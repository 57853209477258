
import React from 'react';
import { data } from './dataPrecincts';
import ButtonPrecinct from './ButtonPrecinct';
import styles from './precincts.module.scss';


export default function ViewPrecinctsMenu () {
    
    const menuItems = [];
    let currMenuItem;
    for (let i = 0; i < data.length; i++) { 
        currMenuItem = data[i];
        menuItems.push(
            <li key={ i }>
                <ButtonPrecinct precinct={ currMenuItem.title } />
            </li>     
        );
    }

    return (
        <nav className={styles.menu}>
            <ul>
                { menuItems }
            </ul>
        </nav>
    );

}