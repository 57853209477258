import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { selectLevelGroup, selectArea, deselectArea } from '.';
import { gsap } from 'gsap/all';


function ButtonResidencesHotspot ( { data, selectLevelGroup, selectArea, deselectArea } ) {

  const containerRef = useRef(null);
  const btnRef = useRef(null);
  const subMenuRef = useRef(null);
  let isMenuOpen = false;

  const getSubMenu = () => {
    const optionsList = [data.rotation];
    const levelGroups = data.levelGroups;
    for (let i = 0; i < levelGroups.length; i++) {
      optionsList.push(
        <li key={i}>
          <button className='residences--hotspot__sub-menu-item' onClick={ () => selectLevelGroup(levelGroups[i]) }>{ 'Level ' + levelGroups[i] }</button>
        </li>
      );
    }
    return optionsList;
  }

  const openSubMenu = () => {
    if (!isMenuOpen) {
      isMenuOpen = true;
      selectArea(data.title);
      btnRef.current.classList.add('residences--hotspot__btn-active');
      subMenuRef.current.style.display = 'block';
      subMenuRef.current.style.top = '0px';
      subMenuRef.current.style.opacity = 0;
      gsap.to(subMenuRef.current, 0.3, { top: '20px', opacity: 1, overwrite: true } );
      document.addEventListener('click', closeSubMenu);
      containerRef.current.addEventListener('mouseleave', onMouseLeaveContainer);
    }
  }

  const onMouseLeaveContainer = (event) => {
    closeSubMenu();
  }

  const closeSubMenu = () => {
    if (isMenuOpen) {
      isMenuOpen = false;
      deselectArea();
      containerRef.current.removeEventListener('mouseleave', onMouseLeaveContainer);
      document.removeEventListener('click', closeSubMenu);
      btnRef.current.classList.remove('residences--hotspot__btn-active');
      gsap.killTweensOf(subMenuRef.current);
      subMenuRef.current.style.display = 'none';
    }
  }

  return (
    <div className='residences--hotspot' ref={ containerRef } style={ { left: data.left, top: data.top } }>
      <button className='residences--hotspot__btn' ref={ btnRef } onMouseEnter={ () => setTimeout(openSubMenu, 0) } onClick={ openSubMenu }>{ data.title }</button>
      <div className='residences--hotspot__sub-menu' ref={ subMenuRef }>
          <ul>
            { getSubMenu() }
          </ul>
      </div> 
    </div>
  );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  selectLevelGroup: (levelGroup) => dispatch(selectLevelGroup(levelGroup)),
  selectArea: (area) => dispatch(selectArea(area)),
  deselectArea: () => dispatch(deselectArea())
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonResidencesHotspot);