import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { selectEnlarged } from '../../shared/enlarged/';
import { data } from './dataResidences';
import { gsap } from 'gsap/all';


function ViewResidencesApartmentViews ( { residences, selectEnlarged } ) {

    const menuRef = useRef(null);
    const btnRef = useRef(null);
    const menuHeightRef = useRef(null);
    const dataList = data.apartmentTypes[residences.apartment];

    useEffect(() => {
        if (dataList.length > 22) {
            menuHeightRef.current = 600;
        } else {
            menuHeightRef.current = 300;
        }
        menuRef.current.style.height = menuHeightRef.current + 'px';
        menuRef.current.style.bottom = '-' + (menuHeightRef.current - 40) + 'px'; //to just show the open/close button
    });

    const getThumbs = () => {
        const optionsList = [];
        for (let i = 0; i < dataList.length; i++) {
            optionsList.push(
                <li key={i} className='residences--apartment__views-tmb'>
                    <button onClick={ () => onThumbSelected('./img/views/' + dataList[i].viewSrc + '.jpg') }>
                        <h2>{ dataList[i].title }</h2>
                        <img src={ './img/views/' + dataList[i].viewSrc + '-tmb.jpg' } alt='view thumbnail' />
                    </button>
                </li>
            );
        }
        return optionsList;
    }

    const onThumbSelected = (enlargedSrc) => {
        selectEnlarged( { srcList: [enlargedSrc] } );
    }

    let isOpen = false;
    const toggleMenu = () => {
        if (isOpen) {
            isOpen = false;
            btnRef.current.innerHTML = '&uarr; VIEWS';
            gsap.to(menuRef.current, 0.3, { bottom: '-' + (menuHeightRef.current - 40) + 'px', overwrite: true } ); //to just show the open/close button
        } else {
            isOpen = true
            btnRef.current.innerHTML = 'X CLOSE';
            gsap.to(menuRef.current, 0.3, { bottom: '0px', overwrite: true } );
        }
    }

    return (
        <div className='residences--apartment__views' ref={ menuRef }>
            <button className='residences--apartment__views-btn' ref={ btnRef } onClick={ toggleMenu }>&uarr; VIEWS</button>
            <ul className='residences--apartment__views-tmbs'>
                { getThumbs() }
            </ul>
        </div>
    );

}


const mapStateToProps = (state) => ({
    residences: state.residences
})

const mapDispatchToProps = (dispatch) => ({
    selectEnlarged: (data) => dispatch(selectEnlarged(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewResidencesApartmentViews)