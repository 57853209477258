import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable'; 
import { data } from './dataMajorProjects'; 
import { selectYear } from './';
import { utilArray } from '../../util/utilArray';


function ButtonMajorProjectsDragger ( { currYear, selectYear } ) {

    //to match the scrubber position with btn clicks
    const currIndex = utilArray.findWithAttr(data, 'year', currYear) < 0 ? 0 : utilArray.findWithAttr(data, 'year', currYear);
    const initialGap = 80;
    const gapBetween = 220;
  
    const progressRef = useRef(null);
    const draggableRef = useRef(null);
    // const slideNoteRef = useRef(null);
    const currentScaleRef = useRef(1);
    
    useEffect(() => {
        currentScaleRef.current = window.innerWidth / 1920;
        draggableRef.current.state.scale = currentScaleRef.current;
        const handleResize = () => {
            currentScaleRef.current = window.innerWidth / 1920;
            draggableRef.current.state.scale = currentScaleRef.current;
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const alignDragger = () => {
        if (draggableRef.current && progressRef.current) {
            let currXPos = (currIndex * gapBetween) + initialGap;
            if (currXPos < 0) currXPos = initialGap;
            draggableRef.current.state.x = currXPos - 12;
            progressRef.current.style.width = currXPos + 'px';
        }
    }
    alignDragger();

    const handleDrag = (event, position) => { //update the current index based on where the dragger is placed
        if (position.x < initialGap) {
            selectYear(data[0].year);
        } else {
            const newIndexPosition = Math.round((position.x - initialGap) / gapBetween);
            if (currYear !== data[newIndexPosition].year) {
                selectYear(data[newIndexPosition].year);
            }
        }
        progressRef.current.style.width = position.x + 'px';
    }

    const handleStop = () => {
        alignDragger();
    }

    return (
        <>
            <div ref={ progressRef } className='major-projects--dragger-progress' style={ { width: (currIndex * gapBetween) + initialGap } }></div>
            <Draggable
              ref={ draggableRef }
              axis='x'
              defaultPosition={ { x: (currIndex * gapBetween) + initialGap - 12, y: 0 } }
              bounds= { { left: 0, right: 1000 } }
              onDrag={ handleDrag }
              onStop={ handleStop }
              scale={ currentScaleRef.current }
            >
                <div className='major-projects--dragger'></div>
            </Draggable>
        </>
  );

}


const mapStateToProps = (state) => ({
    currYear: state.majorProjects.currYear
});

const mapDispatchToProps = (dispatch) => ({
    selectYear: (currYear) => dispatch(selectYear(currYear))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonMajorProjectsDragger);