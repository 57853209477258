
export const data = {
    levels: [
        { 
            title: 'Level 49', 
            src: './img/amenities/bkg--amenities-L49.jpg',
            index: 0,
            hotSpots: [
                { title: 'Executive Lounge', left: '671px', top: '718px', src: './img/amenities/v22-executive-lounge-tmb.jpg', href: './img/gallery/QWT/v22.jpg' }
            ]
        },
        { 
            title: 'Level 7', 
            src: './img/amenities/bkg--amenities-L7.jpg',
            index: 1,
            hotSpots: [
                { title: 'Work Space', left: '912px', top: '812px', align: 'top-right', src: './img/amenities/v18-work-space-tmb.jpg', href: './img/gallery/QWT/v18.jpg' },
                { title: 'Residents Lounge', left: '757px', top: '386px', src: './img/amenities/v19-residents-lounge-tmb.jpg', href: './img/gallery/QWT/v19.jpg' },
                { title: 'Private Dining', left: '637px', top: '613px', align: 'bottom-left', src: './img/amenities/v20-private-dining-tmb.jpg', href: './img/gallery/QWT/v20.jpg' },
                { title: 'Wine Room', left: '1212px', top: '934px', align: 'top-right', src: './img/amenities/v21-wine-room-tmb.jpg', href: './img/gallery/QWT/v21.jpg' }
            ]
        },
        { 
            title: 'Level 6', 
            src: './img/amenities/bkg--amenities-L6.jpg',
            index: 2,
            hotSpots: [
                { title: 'Sound Lounge', left: '800px', top: '821px', align: 'top-right', src: './img/amenities/v15-sound-lounge-tmb.jpg', href: './img/gallery/QWT/v15.jpg' },
                { title: 'Gym', left: '1265px', top: '903px', align: 'top-left', src: './img/amenities/v16-gym-tmb.jpg', href: './img/gallery/QWT/v16.jpg' },
                { title: 'Sports Bar', left: '623px', top: '790px', align: 'top-left', src: './img/amenities/v17-sports-bar-tmb.jpg', href: './img/gallery/QWT/v17.jpg' }
            ]
        },
        { 
            title: 'Level 5', 
            src: './img/amenities/bkg--amenities-L5.jpg',
            index: 3,
            hotSpots: [
                { title: 'Podium Hero', left: '650px', top: '127px', src: './img/amenities/v13-podium-hero-tmb.jpg', href: './img/gallery/QWT/v13.jpg'},
                { title: 'Pool', left: '592px', top: '270px', align: 'bottom-left', src: './img/amenities/v14-pool-tmb.jpg', href: './img/gallery/QWT/v14.jpg'},
            ]
        },
        { 
            title: 'Upper Ground', 
            src: './img/amenities/bkg--amenities-UG.jpg',
            index: 4,
            hotSpots: [
                { title: 'Lobby', left: '572px', top: '547px', align: 'top-right', src: './img/amenities/v12-lobby-tmb.jpg', href: './img/gallery/QWT/v12.jpg' },
                { title: 'Entry', left: '290px', top: '480px', align: 'top-right', src: './img/amenities/v11-entry-tmb.jpg', href: './img/gallery/QWT/v11.jpg' },
                { title: 'Corner_William_Margaret', left: '40px', top: '940px', align: 'top-right', src: './img/amenities/v04-tmb.jpg', href: './img/gallery/QWT/v04.jpg' },
                { title: 'Corner_George_Margaret', left: '370px', top: '120px', src: './img/amenities/v01-tmb.jpg', href: './img/gallery/QWT/v01.jpg'},
            ]
        },
        { 
            title: 'Ground', 
            src: './img/amenities/bkg--amenities-G.jpg',
            index: 5,
            hotSpots: [
                { title: 'William_towards_IRD', left: '1150px', top: '1000px', align: 'top-right', src: './img/amenities/v03-tmb.jpg', href: './img/gallery/QWT/v03.jpg' },
                { title: 'Corner_Margaret_William', left: '370px', top: '1020px', align: 'top-right', src: './img/amenities/v02-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
            ]
        },
    ]
}