
import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap/all';


export default function ButtonHeaderSubMenu ( { title, options } ) {
    
    const containerRef = useRef(null);
    const btnRef = useRef(null);
    const subMenuRef = useRef(null);
    let isMenuOpen = false;

    const optionsList = [];
    let option;
    let lineBreake;
    // Not yet in use
    // let lineGallery;

    for (let i = 0; i < options.length; i++) { 
        
        option = options[i];
        


        if(option.title==="GQWB"){
            option.title="QUEEN'S WHARF BRISBANE IRD";
            lineBreake = "twoline";
        }else if(option.title==="GQWR"){
            option.title="QUEEN'S WHARF TOWER";
            lineBreake = "twoline";
        }else if(option.title==="QWT"){
            option.title="QUEEN'S WHARF TOWER";   
            lineBreake = "twoline";
        }
        else if(option.title==="QWR"){
             option.title="QUEEN'S WHARF RESIDENCES (SOLD OUT)";
             lineBreake = "threeline";
        }else{
            lineBreake ='';
        }
        


        optionsList.push(
            <li key={i}>
                <NavLink 
                className={`header--nav__link-in-sub-menu ${lineBreake}`} activeClassName='header--nav__link-selected' 
                to={option.path}  > { option.title }</NavLink>
            </li>
        );
        
    }

    const openSubMenu = async () => {
        if (!isMenuOpen) {
            isMenuOpen = true;
            subMenuRef.current.style.display = 'block';
            subMenuRef.current.style.top = '75%';
            subMenuRef.current.style.opacity = 0;
            gsap.to(subMenuRef.current, 0.3, { top: '100%', opacity: 1 } );
            document.addEventListener('click', closeSubMenu);
            containerRef.current.addEventListener('mouseleave', onMouseLeaveContainer);
        }
    }

    const onMouseLeaveContainer = (event) => {
        closeSubMenu();
    }

    const closeSubMenu = (event) => {
        if (isMenuOpen) {
            isMenuOpen = false;
            containerRef.current.removeEventListener('mouseleave', onMouseLeaveContainer);
            document.removeEventListener('click', closeSubMenu);
            gsap.killTweensOf(subMenuRef.current);
            subMenuRef.current.style.display = 'none';
        }
    }

    return (
        <>
            <div ref={ containerRef }>
                <button className='header--nav__link' ref={ btnRef } onMouseEnter={ () => setTimeout(openSubMenu, 0) } onClick={ openSubMenu }>{ title }</button>
                <div className='header--nav__sub-menu' ref={ subMenuRef }>
                    <ul>
                        { optionsList }
                    </ul>
                </div> 
            </div> 
        </>       
    );

}