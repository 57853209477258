export const data = [
    { 
        year: 2022,
        projects: [
            { src: './img/location-major-projects/bkg--major-projects_2022.jpg', xPos: '0', yPos: '0' },
        ]
    },
    { 
        year: 2023,
        projects: [
            { src: './img/location-major-projects/bkg--major-projects_2023.jpg', xPos: '0', yPos: '0' },
        ]
    },
    { 
        year: 2024,
        projects: [
            { src: './img/location-major-projects/bkg--major-projects_2024.jpg', xPos: '0', yPos: '0' },
        ]
    },
    /*
    { 
        year: 2025,
        projects: []
    },
    */
    { 
        year: 2026,
        projects: [
            { src: './img/location-major-projects/bkg--major-projects_2026.jpg', xPos: '0', yPos: '0' },
        ]
    },
    { 
        year: 2028,
        projects: [
            { src: './img/location-major-projects/bkg--major-projects_2028.jpg', xPos: '0', yPos: '0' },
        ]
    }
];

