import React from 'react';
import { selectApartment } from './';
import { connect } from 'react-redux';


function ButtonResidencesApartment ( { data, selectApartment } ) {

  return (
    <button className='residences--apartment-btn' style={ { left: data.left, top: data.top } } onClick={ () => selectApartment(data.title) } ></button>
  );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  selectApartment: (apartment) => dispatch(selectApartment(apartment))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonResidencesApartment);