// useEffect not currently use
// import React, { useState,useEffect } from 'react';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ReactPlayer from 'react-player/vimeo'
import LoadContainer from '../../shared/LoadContainer';
import './stylesMovies.scss';


export default function ViewMovies (props) {
    
    const [ isMovieActive, setIsMovieActive ] = useState(false);

    const [ currMovieSrc, setCurrMovieSrc ] = useState('');

    // Not currently use
    // const [count, setCount] = useState(0);

    /*
    useEffect(() => {
        
        const path = window.location.pathname;
        const pathName = path.split('/').pop();

        if(count ==0 && pathName==='queen-wharf-residences'){
            setCount(1);
            playMovie('https://player.vimeo.com/video/450949511');
        }

    });
    */
    

    const playMovie = (src) => {
        const newSrc = src;
        setCurrMovieSrc(newSrc);
        setIsMovieActive(true);
    }


    return (
        <LoadContainer>
            <img src='./img/movies/bkg--movies-1.jpg' width='1920' height='1080' alt='movies bkg' />
            <button className='movies--button__ird' onClick={ () => playMovie('https://player.vimeo.com/video/450951258') }></button>
            <button className='movies--button__construction-timelapse' onClick={ () => playMovie('https://player.vimeo.com/video/523608624?h=8815cace74') }></button>
            <NavLink className='movies--nav-link__more-movies' to='/more-movies'></NavLink>
            <CSSTransition className='movies--enlarged' in={ isMovieActive } timeout={ 300 } classNames='fade' unmountOnExit>
                <div>
                    <ReactPlayer className='movies--enlarged__movie' url={ currMovieSrc } playing={ true } controls={ true } width='1280px' height='720px' />
                    <button className='movies--enlarged__close-btn' onClick={ () => setIsMovieActive(false) } />
                </div>
            </CSSTransition> 
        </LoadContainer>
    );
    




}