import React from 'react';
import { connect } from 'react-redux';
import { selectEnlarged } from '../../shared/enlarged/';
import LoadContainer from '../../shared/LoadContainer';
import './gallery.scss';


function ViewGallery ( { data, selectEnlarged } ) {
    
    const getImages = () => {
        const images = [];
        let currImage;
        for (let i = 0; i < data.length; i++) { 
            currImage = data[i];
            images.push(
                <button key={ i } className='gallery--image' style={ { left: currImage.left, top: currImage.top } } onClick={ () => onImageSelected(i) }>
                    <img src={ currImage.src } alt={ currImage.title } />
                </button>    
            );
        }
        return images;
    }

    const enlargedList = [];
    const captionList = [];
    let isDraggable = true;
    for (let i = 0; i < data.length; i++) { 
        enlargedList.push(data[i].href);
        captionList.push(data[i].caption);
        // If any of these have multiple hrefs, make isDraggable false
        isDraggable = isDraggable && !Array.isArray(data[i].href);
    }

    const onImageSelected = (selectedIndex) => {
        selectEnlarged( { srcList: enlargedList, captionList: captionList, currSrcIndex: selectedIndex, options: {maxHeight: 880}, isDraggable: isDraggable } );
    }

    return (
        <LoadContainer>
            <div className='gallery__images'>
                { getImages() }
            </div>
        </LoadContainer>
    );

}


const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
    selectEnlarged: (data) => dispatch(selectEnlarged(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewGallery);