
export const data = {
    rotationHotspots: {
        0: [
            { title: 'staged opening', left: '1050px', top: '700px', src: './img/masterplan-features/sky-deck2-tmb.jpg' },
            { title: 'sky deck1', left: '980px', top: '545px', src: './img/masterplan-features/sky-deck1-tmb.jpg', href: './img/gallery/sky-deck1.jpg' },
            { title: 'queens wharf tower', left: '1062px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
            { title: 'executive lounge', left: '1052px', top: '350px', src: './img/masterplan-features/v22-executive-lounge-tmb.jpg', href: './img/gallery/QWT/v22.jpg' },
            { title: 'queens wharf residences', left: '953px', top: '420px', src: './img/masterplan-features/queens-wharf-residences-tmb.jpg', href: './img/gallery/queens-wharf-residences.jpg' },
            { title: 'rosewood', left: '789px', top: '448px', src: './img/masterplan-features/rosewood-tmb.jpg' },
            { title: 'dorsett', left: '789px', top: '620px', src: './img/masterplan-features/dorsett-tmb.jpg' },
            { title: 'the star grand', left: '980px', top: '620px', src: './img/masterplan-features/the-star-grand-tmb.jpg', href: './img/gallery/the-star-grand.jpg' },
            { title: 'miller park', left: '713px', top: '820px', src: './img/masterplan-features/miller-park-tmb.jpg', href: './img/gallery/miller-park.jpg' },
            { title: 'neville bonner bridge', left: '1099px', top: '934px', src: './img/masterplan-features/neville-bonner-bridge-tmb.jpg'},
            { title: 't galleria emporium', left: '737px', top: '655px', src: './img/masterplan-features/t-galleria-emporium-tmb.jpg', href: './img/gallery/t-galleria-emporium.jpg' },
            { title: 'waterline park', left: '1300px', top: '690px', src: './img/masterplan-features/waterline-park-tmb.jpg', href: './img/gallery/waterline-park.jpg' },
            { title: 'mangrove walk', left: '1530px', top: '660px', src: './img/masterplan-features/mangrove-walk-tmb.jpg', href: './img/gallery/mangrove-walk.jpg' },
            { title: 'will flow cafe', left: '1723px', top: '570px', src: './img/masterplan-features/will-flow-cafe-tmb.jpg', href: './img/gallery/IRD/boathouse.jpg' },
            { title: 'the landing', left: '1100px', top: '830px', src: './img/masterplan-features/the-landing-tmb.jpg', href: './img/gallery/the-landing.jpg' },
            { title: 'river terrace', left: '975px', top: '752px', src: './img/masterplan-features/river-terrace-tmb.jpg', href: './img/gallery/river-terrace.jpg' },
            { title: 'stephens lane', left: '679px', top: '678px', src: './img/masterplan-features/stephens-lane-tmb.jpg', href: './img/gallery/stephens-lane.jpg' },
            { title: 'old state library', left: '627px', top: '840px', src: './img/masterplan-features/old-state-library-tmb.jpg' },
            { title: 'finger wharves', left: '735px', top: '970px', src: './img/masterplan-features/finger-wharves-tmb.jpg' },
            { title: 'treasury casino', left: '285px', top: '833px', src: './img/masterplan-features/treasury-casino-tmb.jpg' },
            { title: 'velo cafe', left: '595px', top: '915px', src: './img/masterplan-features/velo-cafe-tmb.jpg', href: './img/gallery/velo-cafe.jpg' },
            { title: 'commissariat store', left: '755px', top: '780px', src: './img/masterplan-features/commissariat-store-tmb.jpg', href: './img/gallery/commissariat-store.jpg' },
            { title: 'former DPI building', left: '840px', top: '755px', src: './img/masterplan-features/former-DPI-building-tmb.jpg', href: './img/gallery/former-DPI-building.jpg' },
            { title: 'brisbane steps', left: '907px', top: '795px', src: './img/masterplan-features/brisbane-steps-tmb.jpg', href: './img/gallery/brisbane-steps.jpg' },
        ],
        18: [
            { title: 'queens wharf tower', left: '1190px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
            { title: 'sky deck1', left: '850px', top: '497px', src: './img/masterplan-features/sky-deck1-tmb.jpg', href: './img/gallery/sky-deck1.jpg' },
            { title: 'tower 6', left: '1261px', top: '649px', src: './img/masterplan-features/proposed-commercial-tmb.jpg' },
            { title: 'rosewood', left: '907px', top: '365px', src: './img/masterplan-features/rosewood-tmb.jpg' },
            { title: 'the star grand', left: '885px', top: '590px', src: './img/masterplan-features/the-star-grand-tmb.jpg', href: './img/gallery/the-star-grand.jpg' },
            { title: 'neville bonner bridge', left: '522px', top: '689px', src: './img/masterplan-features/neville-bonner-bridge-tmb.jpg' }, 
            { title: 'the landing', left: '704px', top: '757px', src: './img/masterplan-features/the-landing-tmb.jpg', href: './img/gallery/the-landing.jpg' },
            { title: 'waterline park', left: '950px', top: '870px', src: './img/masterplan-features/waterline-park-tmb.jpg', href: './img/gallery/waterline-park.jpg' },
            { title: 'bicentennial bikeway', left: '976px', top: '994px', src: './img/masterplan-features/bicentennial-bikeway-tmb.jpg' },
            { title: 'finger wharves', left: '570px', top: '617px', src: './img/masterplan-features/finger-wharves-tmb.jpg' },
            { title: 'treasury casino', left: '700px', top: '467px', src: './img/masterplan-features/treasury-casino-tmb.jpg' },
            { title: 'velo cafe', left: '640px', top: '560px', src: './img/masterplan-features/velo-cafe-tmb.jpg', href: './img/gallery/velo-cafe.jpg' },
            { title: 'old state library', left: '700px', top: '542px', src: './img/masterplan-features/old-state-library-tmb.jpg' },
            { title: 'river terrace', left: '765px', top: '655px', src: './img/masterplan-features/river-terrace-tmb.jpg', href: './img/gallery/river-terrace.jpg' },
            { title: 'staged opening', left: '885px', top: '705px', src: './img/masterplan-features/sky-deck2-tmb.jpg' },
            { title: 'miller park', left: '720px', top: '600px', src: './img/masterplan-features/miller-park-tmb.jpg', href: './img/gallery/miller-park.jpg' },
            { title: 'queens wharf visitor centre', left: '1305px', top: '730px', src: './img/masterplan-features/queens-wharf-visitor-centre-tmb.jpg', href: './img/gallery/queens-wharf-visitor-centre.jpg' },
            { title: 'commissariat store', left: '775px', top: '595px', src: './img/masterplan-features/commissariat-store-tmb.jpg', href: './img/gallery/commissariat-store.jpg' },
        ],
        36: [
            { title: 'queens wharf tower', left: '810px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
            { title: 'residents club', left: '830px', top: '680px', src: './img/masterplan-features/v13-podium-hero-tmb.jpg', href: './img/gallery/QWT/v13.jpg' },
            { title: 'sky deck1', left: '1007px', top: '426px', src: './img/masterplan-features/sky-deck1-tmb.jpg', href: './img/gallery/sky-deck1.jpg' },
            { title: 'tower 6', left: '750px', top: '650px', src: './img/masterplan-features/proposed-commercial-tmb.jpg' },
            { title: 'queens wharf residences', left: '920px', top: '420px', src: './img/masterplan-features/queens-wharf-residences-tmb.jpg', href: './img/gallery/queens-wharf-residences.jpg' },
            { title: 'rosewood', left: '1087px', top: '350px', src: './img/masterplan-features/rosewood-tmb.jpg' },
            { title: 'dorsett', left: '1090px', top: '495px', src: './img/masterplan-features/dorsett-tmb.jpg' },
            // { title: 'L7 rec deck', left: '1005px', top: '580px', src: './img/masterplan-features/l7-recreation-deck-tmb.jpg', href: './img/gallery/l7-recreation-deck.jpg' },
            // { title: 'george street entrance', left: '991px', top: '659px', src: './img/masterplan-features/george-street-entrance-tmb.jpg', href: './img/gallery/george-street-entrance.jpg' }, -- grab assets/images/masterplan-features/BMU006-V07A-A.jpg
            // { title: 'main entry lobby', left: '862px', top: '657px', src: './img/masterplan-features/main-entry-lobby-tmb.jpg', href: './img/gallery/main-entry-lobby.jpg' },
            // { title: '22m lap pool', left: '914px', top: '595px', src: './img/masterplan-features/22m-lap-pool-tmb.jpg', href: './img/gallery/22m-lap-pool.jpg' },
            // { title: 'harris lane retail', left: '931px', top: '680px', src: './img/masterplan-features/harris-lane-retail-tmb.jpg', href: './img/gallery/harris-lane-retail.jpg' }
            { title: 'harris lane retail', left: '970px', top: '660px', src: './img/masterplan-features/harris-lane-retail-tmb.jpg', href: './img/gallery/george-street-harris-lane.jpg' },
            { title: 'harris terrace', left: '940px', top: '700px', src: './img/masterplan-features/harris-terrace-tmb.jpg', href: './img/gallery/harris-terrace.jpg' },
            { title: 'the mansions', left: '867px', top: '746px', src: './img/masterplan-features/the-mansions-tmb.jpg', href: './img/gallery/the-mansions.jpg' },
            { title: 'queens wharf visitor centre', left: '740px', top: '800px', src: './img/masterplan-features/queens-wharf-visitor-centre-tmb.jpg', href: './img/gallery/queens-wharf-visitor-centre.jpg' },
            { title: 'the star ballroom', left: '1015px', top: '635px', src: './img/masterplan-features/the-star-ballroom-tmb.jpg', href: './img/gallery/the-star-ballroom.jpg' },
        ],
        54: [
            { title: 'queens wharf tower', left: '750px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
            { title: 'executive lounge', left: '765px', top: '335px', src: './img/masterplan-features/v22-executive-lounge-tmb.jpg', href: './img/gallery/QWT/v22.jpg' },
            { title: 'residents club', left: '745px', top: '580px', src: './img/masterplan-features/v13-podium-hero-tmb.jpg', href: './img/gallery/QWT/v13.jpg' },
            { title: 'tower 6', left: '680px', top: '460px', src: './img/masterplan-features/proposed-commercial-tmb.jpg' },
            { title: 'sky deck1', left: '1063px', top: '475px', src: './img/masterplan-features/sky-deck1-tmb.jpg', href: './img/gallery/sky-deck1.jpg' },
            { title: 'rosewood', left: '985px', top: '420px', src: './img/masterplan-features/rosewood-tmb.jpg' },
            { title: 'dorsett', left: '985px', top: '585px', src: './img/masterplan-features/dorsett-tmb.jpg' },
            { title: 'the star grand', left: '1063px', top: '550px', src: './img/masterplan-features/the-star-grand-tmb.jpg', href: './img/gallery/the-star-grand.jpg' },
            { title: 'queens wharf residences', left: '825px', top: '420px', src: './img/masterplan-features/queens-wharf-residences-tmb.jpg', href: './img/gallery/queens-wharf-residences.jpg' },
            { title: 'neville bonner bridge', left: '1355px', top: '595px', src: './img/masterplan-features/neville-bonner-bridge-tmb.jpg' },
            { title: 'miller park', left: '1229px', top: '726px', src: './img/masterplan-features/miller-park-tmb.jpg', href: './img/gallery/miller-park.jpg' },
            { title: 'commissariat store', left: '1170px', top: '698px', src: './img/masterplan-features/commissariat-store-tmb.jpg', href: './img/gallery/commissariat-store.jpg' },
            { title: 'treasury casino', left: '1454px', top: '909px', src: './img/masterplan-features/treasury-casino-tmb.jpg' },
            { title: 'brisbane steps', left: '1125px', top: '672px', src: './img/masterplan-features/brisbane-steps-tmb.jpg', href: './img/gallery/brisbane-steps.jpg' },
            { title: 'old state library', left: '1325px', top: '775px', src: './img/masterplan-features/old-state-library-tmb.jpg' },
        ]
    }
}