
import React from 'react';
import { data } from './dataPrecincts';
import ViewPrecinct from './ViewPrecinct';
import ViewPrecinctsMenu from './ViewPrecinctsMenu';
import LoadContainer from '../../shared/LoadContainer';
import styles from './precincts.module.scss';


export default function ViewPrecincts () {
    
    const getPrecincts = () => {
        const precincts = [];
        let currPrecinct;
        for (let i = 0; i < data.length; i++) { 
            currPrecinct = data[i];
            precincts.push(
                <ViewPrecinct key={ i } precinct={ currPrecinct.title } />
            );
        }
        return precincts;
    }

    return (
        <>
            <LoadContainer>
                <img className={ 'shared--img-fill ' + styles.bkg } src='img/masterplan-precincts/bkg--masterplan-precincts.jpg' width='1920' height='1080' alt='Bkg' />
            </LoadContainer>
            <div className={ styles.frg }>
                { getPrecincts() }
            </div>
            <ViewPrecinctsMenu />
        </>
    );

}