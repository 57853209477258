import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { closeLevelGroup } from './';
import { data } from './dataResidences';
import ConnectButtonResidencesApartment from './ConnectButtonResidencesApartment';
import ConnectButtonPhotoHotspot from '../../shared/photo-hotspot/ConnectButtonPhotoHotspot';


function ViewResidencesLevelGroup ( { residences, closeLevelGroup } ) {

  const getApartments = () => {
    const btnList = [];
    if (data.levelGroupApartments[residences.levelGroup] !== undefined) {
        const dataList = data.levelGroupApartments[residences.levelGroup]; 
        for (let i = 0; i < dataList.length; i++) { 
            if (dataList[i].type && dataList[i].type === 'photo')
            {
              btnList.push(
                <ConnectButtonPhotoHotspot key={ i } data={ dataList[i] } onSelected={ () => {} } />
              );
            }
            else
            {
              btnList.push(
                <ConnectButtonResidencesApartment key={ i } data={ dataList[i] }  />
              );
            }
        }
    } 
    return btnList;
  }
  
  return (
      <CSSTransition className='residences--level-group' in={ residences.isLevelGroupActive } timeout={ 300 } classNames='fade' unmountOnExit>
          <div>
              <img className='shared--img-fill' src={ './img/floor-plates/BMU006-QWT-Floorplates_L' + residences.levelGroup + '.jpg' } width='1920' height='1080' alt='residences level' />
              <div className='residences--apartment-btns'>
                { getApartments() }
              </div>
              <button className='residences--level-group__close-btn' onClick={ closeLevelGroup } />
          </div>
      </CSSTransition> 
  );

}


const mapStateToProps = (state) => ({
  residences: state.residences
})

const mapDispatchToProps = (dispatch) => ({
  closeLevelGroup: () => dispatch(closeLevelGroup())
})


export default connect(mapStateToProps, mapDispatchToProps)(ViewResidencesLevelGroup)