
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable'; 
import { selectLevel } from './';
import { data } from './dataAmenities';


function ButtonAmenitiesLevelsDragger ( { currLevel, selectLevel } ) {
    
    const [ isDragging, setIsDragging ] = useState(false);

    const draggableRef = useRef(null);
    const btnRef = useRef(null);
    const gap = 40;
    const currentScaleRef = useRef(1);

    if (btnRef.current && !isDragging) {
        btnRef.current.style.y = draggableRef.current.state.y;
        let currYPos = currLevel.index * gap;
        draggableRef.current.state.y = currYPos;
        btnRef.current.style.y = currYPos;
    }

    useEffect(() => {
        currentScaleRef.current = window.innerWidth / 1920;
        draggableRef.current.state.scale = currentScaleRef.current;
        const handleResize = () => {
            currentScaleRef.current = window.innerWidth / 1920;
            draggableRef.current.state.scale = currentScaleRef.current;
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleStart = () => {
        setIsDragging(true);
    }

    const handleDrag = (event, position) => {
        let newIndexPosition = Math.round(position.y / gap);
        if (currLevel.index !== newIndexPosition) {
            selectLevel(data.levels[newIndexPosition]);
        }
    }

    const handleStop = () => {
        setIsDragging(false);
    }

    return (
        <Draggable
            ref={ draggableRef }
            axis='y'
            bounds= { { top: 0, bottom: gap * (data.levels.length - 1) } }
            defaultPosition={ { x: 0, y: currLevel.index * gap } }
            onStart={ handleStart }
            onDrag={ handleDrag }
            onStop={ handleStop }>
                <div className='amenities--ui__levels-dragger' ref={ btnRef }></div>
        </Draggable>
    );

}


const mapStateToProps = (state) => ({
    currLevel: state.amenities.currLevel
});

const mapDispatchToProps = (dispatch) => ({
    selectLevel: (currLevel) => dispatch(selectLevel(currLevel))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAmenitiesLevelsDragger);