
import React from 'react';
import ViewDragger from '../../shared/dragger/ViewDragger';


export default function ViewTeam () {
   
    const data = {
        // Removed './img/team/bkg--team-3.jpg'
        srcList: ['./img/team/bkg--team-1.jpg', './img/team/bkg--team-2.jpg'],
        currSrcIndex: 0,
        uiColour: '#002b47'
    }

    return (
        <ViewDragger data={ data } />
    );

}