
import ViewHome from './home/ViewHome';
import ViewTeam from './team/ViewTeam';
import ViewMovies from './movies/ViewMovies';
import ViewMoreMovies from './movies/ViewMoreMovies';
import ViewPrecincts from './precincts/ViewPrecincts';
import ViewFeatures from './features/ViewFeatures';
import ViewMajorProjects from './majorProjects/ViewMajorProjects';
import ViewAerialMap from './aerialMap/ViewAerialMap';
import ViewAmenities from './amenities/ViewAmenities';
import ViewResidences from './residences/ViewResidences';
import ConnectViewGallery from './gallery/ConnectViewGallery';
import { dataIRD, dataResidences, dataInteriors } from './gallery/dataGallery';
import ViewTourH3SA from './tour/ViewTour_H3SA';
import ViewTourH3SB from './tour/ViewTour_H3SB';
import ViewTourDisplaySuite from './tour/ViewTour_Display_Suite';

export const dataRoutes = [
    {
        title: 'Team',
        path: '/team',
        component: ViewTeam
    },
    {
        title: 'Movies',
        path: '/movies',
        component: ViewMovies
    },
    {
        path: '/more-movies',
        component: ViewMoreMovies
    },
    {
        title: 'Masterplan',
        path: '/masterplan',
        routes: [
            {
                title: 'Precincts',
                path: '/masterplan-precincts',
                component: ViewPrecincts
            },
            {
                title: 'Features',
                path: '/masterplan-features',
                component: ViewFeatures
            }
        ]
    },
    {
        title: 'Location',
        routes: [
            {
                title: 'Major Projects',
                path: '/location-major-projects',
                component: ViewMajorProjects
            },
            {
                title: 'Aerial Map',
                path: '/location-aerial-map',
                component: ViewAerialMap
            }
        ]
    },
    {
        title: 'Apartments',
        routes: [
            {
                title: 'FLOORPLANS',
                path: '/floorplans',
                component: ViewResidences
            },
            {
                title: 'INTERIORS',
                path: '/interiors',
                component: ConnectViewGallery,
                data: dataInteriors
            },
            {
                title: 'VR TOUR H3SA.1',
                path: '/vr-tour-h3sa_1',
                component: ViewTourH3SA,
            },
            {
                title: 'VR TOUR H3SB.1',
                path: '/vr-tour-h3sb_1',
                component: ViewTourH3SB,
            },
            {
                title: 'Display Suite',
                path: '/display-suite',
                component: ViewTourDisplaySuite,
            },
        ]
    },
    {
        title: 'Amenities',
        path: '/amenities',
        component: ViewAmenities
    },
    {
        title: 'Gallery',
        routes: [
            {
                title: 'GQWB',
                path: '/gallery-ird',
                component: ConnectViewGallery,
                data: dataIRD
            },
            {
                title: 'GQWR',
                path: '/gallery-residences',
                component: ConnectViewGallery,
                data: dataResidences
            }
        ]
    },
    {
        title: 'Home',
        path: '/',
        component: ViewHome
    }
];