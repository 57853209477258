import React from 'react';
import { connect } from 'react-redux';


function ViewAerialMapItem ( { item, currItem } ) {
  
  const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase(); //replace spaces with - and remove grammar
  const iconSrc = './img/location-aerial-map/frg--' + itemStripped + '.jpg';

  const display = item === currItem ? 'block' : 'none'; //only if it's the currently active item

  return (
      <img className='shared--img-fill' style={ { display: display } } src={ iconSrc } width='1920' height='1080' alt='item icon' />
  );
  
}


const mapStateToProps = (state) => ({
  currItem: state.aerialMap.currItem
})

export default connect(mapStateToProps)(ViewAerialMapItem);