
export const data = [
    { 
        title: 'Transport',
        options: [
            { title: 'Train' },
            { title: 'Bus' },
            { title: 'Bike & Pedestrian' },
            { title: 'Ferry/CityCat' }
        ]
    },
    { 
        title: 'Amenities',
        options: [
            { title: 'Olympics' },   
            { title: 'Culture' },
            { title: 'Parks' },
            { title: 'Education' },
            { title: 'Shopping' },
            { title: 'Entertainment' },
            { title: 'Dining' }
        ]
    }
];