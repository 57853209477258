import produce from 'immer';
import { data } from './dataAmenities';


// Actions
const SELECT_LEVEL = 'QWR_APP/AMENITIES/SELECT_LEVEL';


// Reducer
export default function reducer (state = { currLevel: data.levels[data.levels.length-1] }, action = {}) {
    switch (action.type) {
        case SELECT_LEVEL :
            return produce(state, draftState => {
                draftState.currLevel = action.currLevel;
            });       
        default :
            return state;
    }
}


// Action Creators
export const selectLevel = (currLevel) => ( {
    type: SELECT_LEVEL,
    currLevel
} );