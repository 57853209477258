import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { data } from './dataFeatures';
import ConnectButtonFeatureHotspot from './ConnectButtonFeatureHotspot';


function ViewFeaturesRotation ( { rotation, currRotation } ) {

  const display = rotation === currRotation ? 'block' : 'none'; //only if it's the currently active rotation
  const stringRotation = rotation < 10 ? ('0' + rotation) : rotation.toString(); //those below 10 have a starting 0
  const fileRef = './img/masterplan-features/360_OVERALL_000' + stringRotation + '.jpg';

  const getHotspots = () => {
    const btnList = [];
    if (data.rotationHotspots[rotation] !== undefined) {
        const dataList = data.rotationHotspots[rotation]; 
        for (let i = 0; i < dataList.length; i++) { 
            btnList.push(
                <ConnectButtonFeatureHotspot key={ i } data={ dataList[i] } onSelected={ onHotspotSelected } />
            );
        }
    } 
    return btnList;
  } 

  const hotspotsContainer = useRef(null);
  const onHotspotSelected = (hotspot) => {
    hotspotsContainer.current.append(hotspot); //to ensure it appears over the other hotspots
  }

  return (
      <div style={ { display: display } }  >
          <img src={ fileRef } alt='Rotation View' />
          <div className='features--hotspots-container' ref={ hotspotsContainer }>
              { getHotspots() }
          </div>
      </div>
  );

}


const mapStateToProps = (state) => ({
  currRotation: state.features.currRotation
});

export default connect(mapStateToProps)(ViewFeaturesRotation);