
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ReactPlayer from 'react-player/vimeo'
import LoadContainer from '../../shared/LoadContainer';


export default function ViewMoreMovies () {
    
    const [ isMovieActive, setIsMovieActive ] = useState(false);

    const [ currMovieSrc, setCurrMovieSrc ] = useState('');

    const playMovie = (src) => {
        const newSrc = src;
        setCurrMovieSrc(newSrc);
        setIsMovieActive(true);
    }

    // Not used
    // const commingSoon = () => {
    //     alert('Coming Soon!');
    // }

    /*
        <LoadContainer>
            <img src='./img/movies/bkg--movies-2.jpg' width='1920' height='1080' alt='movies bkg' />
            <button className='movies--button__residences' onClick={ () => playMovie('https://player.vimeo.com/video/450963701') }></button>
            <button className='movies--button__creation-series-1' onClick={ () => playMovie('https://player.vimeo.com/video/450956303') }></button>
            <button className='movies--button__creation-series-2' onClick={ () => playMovie('https://player.vimeo.com/video/450957763') }></button>
            <button className='movies--button__creation-series-3' onClick={ () => playMovie('https://player.vimeo.com/video/450963569') }></button>
            <NavLink className='movies--nav-link__movies' to='/movies'></NavLink>
            <CSSTransition className='movies--enlarged' in={ isMovieActive } timeout={ 300 } classNames='fade' unmountOnExit>
                <div>
                    <ReactPlayer className='movies--enlarged__movie' url={ currMovieSrc } playing={ true } controls={ true } width='1280px' height='720px' />
                    <button className='movies--enlarged__close-btn' onClick={ () => setIsMovieActive(false) } />
                </div>
            </CSSTransition> 
        </LoadContainer>
    */


    return (
        <LoadContainer>
            <img src='./img/movies/bkg--movies-2.jpg' width='1920' height='1080' alt='movies bkg' />
            <button className='movies--button__residences' onClick={ () => playMovie('https://player.vimeo.com/video/704358953') }></button>
            <button className='movies--button__creation-series-1' onClick={ () => playMovie('https://player.vimeo.com/video/678549729?h=6035be9b02') }></button>
            <button className='movies--button__creation-series-2' onClick={ () => playMovie('https://player.vimeo.com/video/681164264?h=9a636b1338') }></button>
            <button className='movies--button__creation-series-3' onClick={ () => playMovie('https://player.vimeo.com/video/687341395?h=2d18882c55') }></button>
            <NavLink className='movies--nav-link__movies' to='/movies'></NavLink>
            <CSSTransition className='movies--enlarged' in={ isMovieActive } timeout={ 300 } classNames='fade' unmountOnExit>
                <div>
                    <ReactPlayer className='movies--enlarged__movie' url={ currMovieSrc } playing={ true } controls={ true } width='1280px' height='720px' />
                    <button className='movies--enlarged__close-btn' onClick={ () => setIsMovieActive(false) } />
                </div>
            </CSSTransition> 
        </LoadContainer>
    );

}