
import React from 'react';
import { data } from './dataMajorProjects';
import ConnectButtonMajorProjectsYear from './ConnectButtonMajorProjectsYear';
import ConnectButtonMajorProjectsDragger from './ConnectButtonMajorProjectsDragger';


export default function ViewMajorProjectsMenu () {
    
    const menuItems = [];
    let currMenuItem;
    for (let i = 0; i < data.length; i++) { 
        currMenuItem = data[i].year;
        menuItems.push(
            <li key={ i }>
                <ConnectButtonMajorProjectsYear year={ currMenuItem } />
            </li>     
        );
    }

    return (
        <nav className='major-projects--menu'>
            <ul className='major-projects--menu__list'>
                { menuItems }
            </ul>
            <ConnectButtonMajorProjectsDragger />
        </nav>
    );

}