
export const data = [
    { title: 'Queen’s Wharf Residences' },
    { title: 'Queen’s Wharf Tower'},
    { title: 'Proposed Commercial'},
    { title: 'Historical' },
    { title: 'Culinary' },
    { title: 'Education' },
    { title: 'Queen’s Walk' },
    { title: 'River Walk' },
    { title: 'Recreational' },
    { title: 'Cultural' },
    { title: 'Hotel' },
    { title: 'Retail' },
    { title: 'Entertainment' },
    { title: 'Indigenous Walk' }
];