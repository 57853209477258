
import React from 'react';
import { data } from './dataAerialMap';
import ConnectButtonAerialMapItem from './ConnectButtonAerialMapItem';
import './stylesAerialMap.scss'


export default function ViewAerialMapMenu ( { zoomInFunc, zoomOutFunc } ) {
    
    const menuItems = [];
    let currMenuGroup;
    let currMenuItem;
    let currIndex = 0;
    for (let i = 0; i < data.length; i++) { 
        currMenuGroup = data[i].options;
        menuItems.push(
            <h2 className='aerial-map--title' key={ currIndex }>{ data[i].title }</h2>
        );
        currIndex++;
        for (let j = 0; j < currMenuGroup.length; j++) { 
            currMenuItem = currMenuGroup[j];
            menuItems.push(
                <ConnectButtonAerialMapItem key={currIndex } item={ currMenuItem.title } />
            );
            currIndex++;
        }
    }

    return (
        <div className='aerial-map--menu'>
            <div className='aerial-map--menu-options'>
                { menuItems }
            </div>
            <div className='aerial-map--zoom-tools'>
                <p className='aerial-map--zoom-tools--title'>Zoom</p>
                    {/* 35 both to 36.25 */}
                <button onClick={ zoomInFunc }><img src='img/interface/icon--zoom-in.png' width='36.25' height='36.25' alt='Zoom In icon' /></button>
                <button onClick={ zoomOutFunc }><img src='img/interface/icon--zoom-out.png' width='36.25' height='36.25' alt='Zoom Out icon' /></button>
            </div>
        </div>
    );

}