
import React from 'react';
import { data } from './dataAerialMap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ViewAerialMapMenu from './ViewAerialMapMenu';
import ConnectViewAerialMapItem from './ConnectViewAerialMapItem';
// import LoadContainer from '../../shared/LoadContainer';
import './stylesAerialMap.scss'


export default function ViewAerialMap () {
    
    const items = [];
    let currGroup;
    let currItem;
    let currIndex = 0;
    for (let i = 0; i < data.length; i++) { 
        currGroup = data[i].options;
        for (let j = 0; j < currGroup.length; j++) { 
            currItem = currGroup[j];
            items.push(
                <ConnectViewAerialMapItem key={ currIndex } item={ currItem.title } />
            );
            currIndex++;
        }
    }

    return (
        // <LoadContainer>
        <div className='aerial-map--transform-container'>
            <TransformWrapper
                maxScale={3}
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <TransformComponent>
                        <img className='shared--img-fill aerial-map--bkg' src='img/location-aerial-map/bkg--aerial-map.jpg' width='1480' height='980' alt='Bkg' />
                        <div className='aerial-map--frg'>
                            { items }
                        </div>
                    </TransformComponent>
                    <ViewAerialMapMenu
                        zoomInFunc={ zoomIn }
                        zoomOutFunc={ zoomOut }
                     />
                </>
                )}
            </TransformWrapper>
        </div>
        // </LoadContainer>
    );
}