import React from 'react'
import { connect } from 'react-redux';
import { selectItem } from './';


function ButtonAerialMapItem ( { item, currItem, selectItem } ) {
  
  const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase(); //replace spaces with - and remove grammar
  const iconSrc = './img/location-aerial-map/icon--' + itemStripped + '.png';
  
  return (
    <button 
      className={ item === currItem ? 'aerial-map--btn__active' : 'aerial-map--btn' } 
      // onMouseEnter={ () => selectItem(item) } 
      onClick={ () => selectItem( item ) } 
    >
      <img src={ iconSrc } width='36.25' height='36.25' alt='item icon' />
      { item }
    </button>
  );
}


const mapStateToProps = (state) => ({
  currItem: state.aerialMap.currItem
});

const mapDispatchToProps = (dispatch) => ({
  selectItem: (currItem) => dispatch(selectItem(currItem))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAerialMapItem);