const PENTHOUSES = 'PENTHOUSES';
const DIAMOND_RESIDENCES = 'DIAMOND RESIDENCES';
const RUBY_RESIDENCES = 'RUBY RESIDENCES';
const EMERALD_RESIDENCES = 'EMERALD RESIDENCES';

export const data = {
    rotationHotspots: {
        0: [
            { title: PENTHOUSES, left: '375px', top: '245px' },
            { title: DIAMOND_RESIDENCES, left: '625px', top: '245px' },
            { title: RUBY_RESIDENCES, left: '375px', top: '620px' },
            { title: EMERALD_RESIDENCES, left: '625px', top: '620px' }
        ],
        18: [
            { title: PENTHOUSES, left: '450px', top: '245px' },
            { title: DIAMOND_RESIDENCES, left: '700px', top: '245px' },
            { title: RUBY_RESIDENCES, left: '450px', top: '620px' },
            { title: EMERALD_RESIDENCES, left: '700px', top: '620px' }
        ],
        36: [
            { title: PENTHOUSES, left: '1100px', top: '245px' },
            { title: DIAMOND_RESIDENCES, left: '1350px', top: '245px' },
            { title: RUBY_RESIDENCES, left: '1100px', top: '620px' },
            { title: EMERALD_RESIDENCES, left: '1350px', top: '620px' }
        ],
        54: [
            { title: PENTHOUSES, left: '1000px', top: '245px' },
            { title: DIAMOND_RESIDENCES, left: '1250px', top: '245px' },
            { title: RUBY_RESIDENCES, left: '1000px', top: '620px' },
            { title: EMERALD_RESIDENCES, left: '1250px', top: '620px' }
        ]
    },
    rotationPhotoHotspots: {
        0: [
            // { title: 'queens wharf tower', left: '1062px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        18: [
            // { title: 'queens wharf tower', left: '1190px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        36: [
            // { title: 'queens wharf tower', left: '810px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        54: [
            // { title: 'queens wharf tower', left: '750px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },},
        ]
    },
    levelGroups: {
        'PENTHOUSES': [ '71' ],
        'DIAMOND RESIDENCES': [ '64-70', '58-63', '56-57', '55', '53-54', '52', '51', '50', '49' ],
        'RUBY RESIDENCES': [ '48', '46-47', '45', '44', '43', '41-42', '39-40', '38', '37', '36', '31-35' ],
        'EMERALD RESIDENCES': [ '28-30', '25-27', '22-24', '21', '19-20', '18', '16-17', '14-15', '13', '9-12', '8' ],
    },
    rotationOverlays: {
        0: {
            'DIAMOND RESIDENCES': { src: './img/residences/frame_00_highlight_Diamond.png', top: 0, left: 0 },
            'EMERALD RESIDENCES': { src: './img/residences/frame_00_highlight_Emerald.png', top: 0, left: 0 },
            'PENTHOUSES': { src: './img/residences/frame_00_highlight_Penthouses.png', top: 0, left: 0 },
            'RUBY RESIDENCES': { src: './img/residences/frame_00_highlight_Ruby.png', top: 0, left: 0 },
        },
        18: {
            'DIAMOND RESIDENCES': { src: './img/residences/frame_18_highlight_Diamond.png', top: 0, left: 0 },
            'EMERALD RESIDENCES': { src: './img/residences/frame_18_highlight_Emerald.png', top: 0, left: 0 },
            'PENTHOUSES': { src: './img/residences/frame_18_highlight_Penthouses.png', top: 0, left: 0 },
            'RUBY RESIDENCES': { src: './img/residences/frame_18_highlight_Ruby.png', top: 0, left: 0 },
        },
        36: {
            'DIAMOND RESIDENCES': { src: './img/residences/frame_36_highlight_Diamond.png', top: 0, left: 0 },
            'EMERALD RESIDENCES': { src: './img/residences/frame_36_highlight_Emerald.png', top: 0, left: 0 },
            'PENTHOUSES': { src: './img/residences/frame_36_highlight_Penthouses.png', top: 0, left: 0 },
            'RUBY RESIDENCES': { src: './img/residences/frame_36_highlight_Ruby.png', top: 0, left: 0 },
        },
        54: {
            'DIAMOND RESIDENCES': { src: './img/residences/frame_54_highlight_Diamond.png', top: 0, left: 0 },
            'EMERALD RESIDENCES': { src: './img/residences/frame_54_highlight_Emerald.png', top: 0, left: 0 },
            'PENTHOUSES': { src: './img/residences/frame_54_highlight_Penthouses.png', top: 0, left: 0 },
            'RUBY RESIDENCES': { src: './img/residences/frame_54_highlight_Ruby.png', top: 0, left: 0 },
        },
    },
    levelGroupApartments: {
        '71': [
            { title: 'E3DS.1', left: '676px', top: '368px' },
            { title: 'E3CS.1', left: '812px', top: '724px' },
            { title: 'E3BS.1', left: '1203px', top: '644px' },
            { title: 'E3AS.1', left: '1071px', top: '276px' },
        ],
        '64-70': [
            { title: 'H2HS.2', left: '938px', top: '287px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1AS.1', left: '999px', top: '724px' },
            { title: 'H3BS.1', left: '1192px', top: '756px' },
            { title: 'H2AS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '58-63': [
            { title: 'H2HS.1', left: '938px', top: '287px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1AS.1', left: '999px', top: '724px' },
            { title: 'H3BS.1', left: '1192px', top: '756px' },
            { title: 'H2AS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '56-57': [
            { title: 'H2HS.1', left: '938px', top: '287px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1CS.1', left: '999px', top: '724px' },
            { title: 'H3DS.1', left: '1192px', top: '756px' },
            { title: 'H2FS.2', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '55': [
            { title: 'H2HS.1', left: '938px', top: '287px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1AS.1', left: '999px', top: '724px' },
            { title: 'H3BS.1', left: '1192px', top: '756px' },
            { title: 'H2AS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '53-54': [
            { title: 'H2GS.1', left: '938px', top: '285px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1AS.1', left: '999px', top: '724px' },
            { title: 'H3BS.1', left: '1192px', top: '756px' },
            { title: 'H2AS.2', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '52': [
            { title: 'H2GS.1', left: '938px', top: '285px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1CS.1', left: '999px', top: '724px' },
            { title: 'H3DS.1', left: '1192px', top: '756px' },
            { title: 'H2FS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '51': [
            { title: 'H2GS.1', left: '938px', top: '286px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.2', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1CS.1', left: '999px', top: '724px' },
            { title: 'H3DS.1', left: '1192px', top: '756px' },
            { title: 'H2FS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '50': [
            { title: 'H2ES.1', left: '938px', top: '267px' },
            { title: 'H2DS.1', left: '711px', top: '203px' },
            { title: 'H2CS.1', left: '664px', top: '356px' },
            { title: 'H2JS.1', left: '664px', top: '546px' },
            { title: 'H3ES.1', left: '688px', top: '756px' },
            { title: 'H1DS.1', left: '882px', top: '724px' },
            { title: 'H1AS.1', left: '999px', top: '724px' },
            { title: 'H3BS.1', left: '1192px', top: '756px' },
            { title: 'H2AS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
        ],
        '49': [
            { title: 'H1AS.1', left: '999px', top: '724px' },
            { title: 'H3BS.1', left: '1192px', top: '756px' },
            { title: 'H2AS.1', left: '1226px', top: '510px' },
            { title: 'H3AS.1', left: '1192px', top: '263px' },
            { type: 'photo', title: 'Executive Lounge', left: '671px', top: '718px', src: './img/amenities/v22-executive-lounge-tmb.jpg', href: './img/gallery/QWT/v22.jpg' },
        ],
        '48': [
            { title: 'M1BS.3', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.3', left: '999px', top: '724px' },
            { title: 'M1BS.3', left: '881px', top: '724px' },
            { title: 'M3AS.1', left: '688px', top: '754px' },
            { title: 'M2MS.1', left: '668px', top: '505px' },
            { title: 'M1DS.4', left: '668px', top: '325px' },
            { title: 'M2FS.2', left: '716px', top: '202px' },
            { title: 'M1AS.3', left: '881px', top: '294px' },
        ],
        '46-47': [
            { title: 'M1BS.3', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.3', left: '999px', top: '724px' },
            { title: 'M1BS.3', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2LS.1', left: '668px', top: '597px' },
            { title: 'M1HS.1', left: '668px', top: '445px' },
            { title: 'M1DS.4', left: '668px', top: '325px' },
            { title: 'M2FS.2', left: '716px', top: '202px' },
            { title: 'M1AS.3', left: '881px', top: '294px' },
        ],
        '45': [
            { title: 'M1BS.3', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.3', left: '999px', top: '724px' },
            { title: 'M1BS.3', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2ES.3', left: '668px', top: '597px' },
            { title: 'M1HS.1', left: '668px', top: '445px' },
            { title: 'M1DS.4', left: '668px', top: '325px' },
            { title: 'M2FS.2', left: '716px', top: '202px' },
            { title: 'M1AS.3', left: '881px', top: '294px' },
        ],
        '44': [
            { title: 'M1BS.3', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.3', left: '999px', top: '724px' },
            { title: 'M1BS.3', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2ES.2', left: '668px', top: '597px' },
            { title: 'M1FS.2', left: '668px', top: '445px' },
            { title: 'M1DS.3', left: '668px', top: '325px' },
            { title: 'M2FS.2', left: '716px', top: '202px' },
            { title: 'M1AS.3', left: '881px', top: '294px' },
        ],
        '43': [
            { title: 'M1BS.3', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.3', left: '999px', top: '724px' },
            { title: 'M1BS.3', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2ES.2', left: '668px', top: '597px' },
            { title: 'M1FS.1', left: '668px', top: '445px' },
            { title: 'M1DS.3', left: '668px', top: '325px' },
            { title: 'M2FS.2', left: '716px', top: '202px' },
            { title: 'M1AS.3', left: '881px', top: '294px' },
        ],
        '41-42': [
            { title: 'M1BS.2', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.2', left: '999px', top: '724px' },
            { title: 'M1BS.2', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2ES.2', left: '668px', top: '597px' },
            { title: 'M1FS.1', left: '668px', top: '445px' },
            { title: 'M1DS.3', left: '668px', top: '325px' },
            { title: 'M2GS.2', left: '716px', top: '202px' },
            { title: 'M1AS.2', left: '881px', top: '294px' },
        ],
        '39-40': [
            { title: 'M1GS.1', left: '999px', top: '294px' },
            { title: 'M2HS.1', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2JS.1', left: '1187px', top: '789px' },
            { title: 'M1ES.1', left: '999px', top: '724px' },
            { title: 'M1GS.1', left: '881px', top: '724px' },
            { title: 'M2HS.1', left: '710px', top: '789px' },
            { title: 'M2ES.2', left: '668px', top: '597px' },
            { title: 'M1FS.1', left: '668px', top: '445px' },
            { title: 'M1DS.3', left: '668px', top: '325px' },
            { title: 'M2KS.1', left: '716px', top: '202px' },
            { title: 'M1ES.1', left: '881px', top: '294px' },
        ],
        '38': [
            { title: 'M1BS.2', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.2', left: '999px', top: '724px' },
            { title: 'M1BS.2', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2ES.2', left: '668px', top: '597px' },
            { title: 'M1CS.3', left: '668px', top: '445px' },
            { title: 'M1DS.2', left: '668px', top: '325px' },
            { title: 'M2GS.2', left: '716px', top: '202px' },
            { title: 'M1AS.2', left: '881px', top: '294px' },
        ],
        '37': [
            { title: 'M1BS.2', left: '999px', top: '294px' },
            { title: 'M2AS.2', left: '1184px', top: '223px' },
            { title: 'M2BS.2', left: '1218px', top: '393px' },
            { title: 'M2CS.2', left: '1227px', top: '597px' },
            { title: 'M2DS.2', left: '1187px', top: '789px' },
            { title: 'M1AS.2', left: '999px', top: '724px' },
            { title: 'M1BS.2', left: '881px', top: '724px' },
            { title: 'M2AS.2', left: '710px', top: '789px' },
            { title: 'M2ES.2', left: '668px', top: '597px' },
            { title: 'M1CS.2', left: '668px', top: '445px' },
            { title: 'M1DS.2', left: '668px', top: '325px' },
            { title: 'M2GS.2', left: '716px', top: '202px' },
            { title: 'M1AS.2', left: '881px', top: '294px' },
        ],
        '36': [
            { title: 'M1BS.2', left: '999px', top: '294px' },
            { title: 'M2AS.1', left: '1184px', top: '223px' },
            { title: 'M2BS.1', left: '1218px', top: '393px' },
            { title: 'M2CS.1', left: '1227px', top: '597px' },
            { title: 'M2DS.1', left: '1187px', top: '789px' },
            { title: 'M1AS.2', left: '999px', top: '724px' },
            { title: 'M1BS.2', left: '881px', top: '724px' },
            { title: 'M2AS.1', left: '710px', top: '789px' },
            { title: 'M2ES.1', left: '668px', top: '597px' },
            { title: 'M1CS.1', left: '668px', top: '445px' },
            { title: 'M1DS.1', left: '668px', top: '325px' },
            { title: 'M2GS.1', left: '716px', top: '202px' },
            { title: 'M1AS.2', left: '881px', top: '294px' },
        ],
        '31-35': [
            { title: 'M1BS.1', left: '999px', top: '294px' },
            { title: 'M2AS.1', left: '1184px', top: '223px' },
            { title: 'M2BS.1', left: '1218px', top: '393px' },
            { title: 'M2CS.1', left: '1227px', top: '597px' },
            { title: 'M2DS.1', left: '1187px', top: '789px' },
            { title: 'M1AS.1', left: '999px', top: '724px' },
            { title: 'M1BS.1', left: '881px', top: '724px' },
            { title: 'M2AS.1', left: '710px', top: '789px' },
            { title: 'M2ES.1', left: '668px', top: '597px' },
            { title: 'M1CS.1', left: '668px', top: '445px' },
            { title: 'M1DS.1', left: '668px', top: '325px' },
            { title: 'M2FS.1', left: '716px', top: '202px' },
            { title: 'M1AS.1', left: '881px', top: '294px' },
        ],
        '28-30': [
            { title: 'L1DS.3', left: '881px', top: '294px' },
            { title: 'L2BS.2', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1GS.2', left: '668px', top: '445px' },
            { title: 'L1ES.2', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2AS.2', left: '710px', top: '813px' },
            { title: 'L1CS.3', left: '881px', top: '724px' },
            { title: 'L1DS.3', left: '999px', top: '724px' },
            { title: 'L2BS.2', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.2', left: '1227px', top: '569px' },
            { title: 'L1BS.2', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2AS.2', left: '1184px', top: '202px' },
            { title: 'L1CS.3', left: '999px', top: '294px' },
        ],
        '25-27': [
            { title: 'L1DS.3', left: '881px', top: '294px' },
            { title: 'L2BS.2', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1GS.2', left: '668px', top: '445px' },
            { title: 'L1HS.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2AS.2', left: '710px', top: '813px' },
            { title: 'L1CS.3', left: '881px', top: '724px' },
            { title: 'L1DS.3', left: '999px', top: '724px' },
            { title: 'L2BS.2', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.2', left: '1227px', top: '569px' },
            { title: 'L1BS.2', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2AS.2', left: '1184px', top: '202px' },
            { title: 'L1CS.3', left: '999px', top: '294px' },
        ],
        '22-24': [
            { title: 'L1DS.3', left: '881px', top: '294px' },
            { title: 'L2BS.2', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1GS.2', left: '668px', top: '445px' },
            { title: 'L1ES.2', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2AS.2', left: '710px', top: '813px' },
            { title: 'L1CS.3', left: '881px', top: '724px' },
            { title: 'L1DS.3', left: '999px', top: '724px' },
            { title: 'L2BS.2', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.2', left: '1227px', top: '569px' },
            { title: 'L1BS.2', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2AS.2', left: '1184px', top: '202px' },
            { title: 'L1CS.3', left: '999px', top: '294px' },
        ],
        '21': [
            { title: 'L1DS.3', left: '881px', top: '294px' },
            { title: 'L2BS.2', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1GS.2', left: '668px', top: '445px' },
            { title: 'L1HS.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2AS.2', left: '710px', top: '813px' },
            { title: 'L1CS.3', left: '881px', top: '724px' },
            { title: 'L1DS.3', left: '999px', top: '724px' },
            { title: 'L2BS.2', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.2', left: '1227px', top: '569px' },
            { title: 'L1BS.2', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2AS.2', left: '1184px', top: '202px' },
            { title: 'L1CS.3', left: '999px', top: '294px' },
        ],
        '19-20': [
            { title: 'L1DS.2', left: '881px', top: '294px' },
            { title: 'L2CS.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1GS.1', left: '668px', top: '445px' },
            { title: 'L1HS.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2DS.1', left: '710px', top: '813px' },
            { title: 'L1CS.2', left: '881px', top: '724px' },
            { title: 'L1DS.2', left: '999px', top: '724px' },
            { title: 'L2CS.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2DS.1', left: '1184px', top: '202px' },
            { title: 'L1CS.2', left: '999px', top: '294px' },
        ],
        '18': [
            { title: 'L1DS.2', left: '881px', top: '294px' },
            { title: 'L2CS.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1GS.1', left: '668px', top: '445px' },
            { title: 'L1ES.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2DS.1', left: '710px', top: '813px' },
            { title: 'L1CS.2', left: '881px', top: '724px' },
            { title: 'L1DS.2', left: '999px', top: '724px' },
            { title: 'L2CS.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2DS.1', left: '1184px', top: '202px' },
            { title: 'L1CS.2', left: '999px', top: '294px' },
        ],
        '16-17': [
            { title: 'L1KS.1', left: '881px', top: '294px' },
            { title: 'L2ES.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1FS.1', left: '668px', top: '445px' },
            { title: 'L1ES.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2FS.1', left: '710px', top: '813px' },
            { title: 'L1JS.1', left: '881px', top: '724px' },
            { title: 'L1KS.1', left: '999px', top: '724px' },
            { title: 'L2ES.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2FS.1', left: '1184px', top: '202px' },
            { title: 'L1JS.1', left: '999px', top: '294px' },
        ],
        '14-15': [
            { title: 'L1DS.2', left: '881px', top: '294px' },
            { title: 'L2CS.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1FS.1', left: '668px', top: '445px' },
            { title: 'L1HS.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2DS.1', left: '710px', top: '813px' },
            { title: 'L1CS.2', left: '881px', top: '724px' },
            { title: 'L1DS.2', left: '999px', top: '724px' },
            { title: 'L2CS.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2DS.1', left: '1184px', top: '202px' },
            { title: 'L1CS.2', left: '999px', top: '294px' },
        ],
        '13': [
            { title: 'L1DS.2', left: '881px', top: '294px' },
            { title: 'L2CS.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1FS.1', left: '668px', top: '445px' },
            { title: 'L1HS.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2DS.1', left: '710px', top: '813px' },
            { title: 'L1CS.2', left: '881px', top: '724px' },
            { title: 'L1DS.2', left: '999px', top: '724px' },
            { title: 'L2CS.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2DS.1', left: '1184px', top: '202px' },
            { title: 'L1CS.2', left: '999px', top: '294px' },
        ],
        '9-12': [
            { title: 'L1DS.1', left: '881px', top: '294px' },
            { title: 'L2BS.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1FS.1', left: '668px', top: '445px' },
            { title: 'L1ES.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2AS.1', left: '710px', top: '813px' },
            { title: 'L1CS.1', left: '881px', top: '724px' },
            { title: 'L1DS.1', left: '999px', top: '724px' },
            { title: 'L2BS.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2AS.1', left: '1184px', top: '202px' },
            { title: 'L1CS.1', left: '999px', top: '294px' },
        ],
        '8': [
            { title: 'L1DS.1', left: '881px', top: '294px' },
            { title: 'L2BS.1', left: '716px', top: '202px' },
            { title: 'L1AM.2', left: '668px', top: '325px' },
            { title: 'L1FS.1', left: '668px', top: '445px' },
            { title: 'L1ES.1', left: '668px', top: '568px' },
            { title: 'L1AS.1', left: '668px', top: '689px' },
            { title: 'L2AS.1', left: '710px', top: '813px' },
            { title: 'L1CS.1', left: '881px', top: '724px' },
            { title: 'L1DS.1', left: '999px', top: '724px' },
            { title: 'L2BS.1', left: '1187px', top: '813px' },
            { title: 'L1AM.1', left: '1227px', top: '689px' },
            { title: 'L1BM.1', left: '1227px', top: '569px' },
            { title: 'L1BS.1', left: '1218px', top: '445px' },
            { title: 'L1AS.1', left: '1218px', top: '325px' },
            { title: 'L2AS.1', left: '1184px', top: '202px' },
            { title: 'L1CS.1', left: '999px', top: '294px' },
        ],
    },
    apartmentTypes: {
        'E3AS.1':[
            { title: '7101', viewSrc: 'Lv71-360-Morning-E' },
        ],
        'E3BS.1':[
            { title: '7102', viewSrc: 'Lv71-360-Morning-S' },
        ],
        'E3CS.1':[
            { title: '7103', viewSrc: 'Lv71-360-Morning-W' },
        ],
        'E3DS.1':[
            { title: '7104', viewSrc: 'Lv71-360-Morning-N' },
        ],
        'H1AS.1':[
            { title: '4904', viewSrc: 'Lv49-360-Morning-SW' },
            { title: '5004', viewSrc: 'Lv49-360-Morning-SW' },
            { title: '5304', viewSrc: 'Lv52-360-Morning-SW' },
            { title: '5404', viewSrc: 'Lv55-360-Morning-SW' },
            { title: '5504', viewSrc: 'Lv55-360-Morning-SW' },
            { title: '5804', viewSrc: 'Lv58-360-Morning-SW' },
            { title: '5904', viewSrc: 'Lv58-360-Morning-SW' },
            { title: '6004', viewSrc: 'Lv61-360-Morning-SW' },
            { title: '6104', viewSrc: 'Lv61-360-Morning-SW' },
            { title: '6204', viewSrc: 'Lv61-360-Morning-SW' },
            { title: '6304', viewSrc: 'Lv64-360-Morning-SW' },
            { title: '6404', viewSrc: 'Lv64-360-Morning-SW' },
            { title: '6504', viewSrc: 'Lv64-360-Morning-SW' },
            { title: '6604', viewSrc: 'Lv67-360-Morning-SW' },
            { title: '6704', viewSrc: 'Lv67-360-Morning-SW' },
            { title: '6804', viewSrc: 'Lv69-360-Morning-SW' },
            { title: '6904', viewSrc: 'Lv69-360-Morning-SW' },
            { title: '7004', viewSrc: 'Lv69-360-Morning-SW' },
        ],
        'H1CS.1':[
            { title: '5104', viewSrc: 'Lv52-360-Morning-SW' },
            { title: '5204', viewSrc: 'Lv52-360-Morning-SW' },
            { title: '5604', viewSrc: 'Lv55-360-Morning-SW' },
            { title: '5704', viewSrc: 'Lv58-360-Morning-SW' },
        ],
        'H1DS.1':[
            { title: '5005', viewSrc: 'Lv49-360-Morning-SW' },
            { title: '5105', viewSrc: 'Lv52-360-Morning-SW' },
            { title: '5205', viewSrc: 'Lv52-360-Morning-SW' },
            { title: '5305', viewSrc: 'Lv52-360-Morning-SW' },
            { title: '5405', viewSrc: 'Lv55-360-Morning-SW' },
            { title: '5505', viewSrc: 'Lv55-360-Morning-SW' },
            { title: '5605', viewSrc: 'Lv55-360-Morning-SW' },
            { title: '5705', viewSrc: 'Lv58-360-Morning-SW' },
            { title: '5805', viewSrc: 'Lv58-360-Morning-SW' },
            { title: '5905', viewSrc: 'Lv58-360-Morning-SW' },
            { title: '6005', viewSrc: 'Lv61-360-Morning-SW' },
            { title: '6105', viewSrc: 'Lv61-360-Morning-SW' },
            { title: '6205', viewSrc: 'Lv61-360-Morning-SW' },
            { title: '6305', viewSrc: 'Lv64-360-Morning-SW' },
            { title: '6405', viewSrc: 'Lv64-360-Morning-SW' },
            { title: '6505', viewSrc: 'Lv64-360-Morning-SW' },
            { title: '6605', viewSrc: 'Lv67-360-Morning-SW' },
            { title: '6705', viewSrc: 'Lv67-360-Morning-SW' },
            { title: '6805', viewSrc: 'Lv69-360-Morning-SW' },
            { title: '6905', viewSrc: 'Lv69-360-Morning-SW' },
            { title: '7005', viewSrc: 'Lv69-360-Morning-SW' },
        ],
        'H2AS.1':[
            { title: '4902', viewSrc: 'Lv49-360-Morning-SE' },
            { title: '5002', viewSrc: 'Lv49-360-Morning-SE' },
            { title: '5502', viewSrc: 'Lv55-360-Morning-SE' },
            { title: '5802', viewSrc: 'Lv58-360-Morning-SE' },
            { title: '5902', viewSrc: 'Lv58-360-Morning-SE' },
            { title: '6002', viewSrc: 'Lv61-360-Morning-SE' },
            { title: '6102', viewSrc: 'Lv61-360-Morning-SE' },
            { title: '6202', viewSrc: 'Lv61-360-Morning-SE' },
            { title: '6302', viewSrc: 'Lv64-360-Morning-SE' },
            { title: '6402', viewSrc: 'Lv64-360-Morning-SE' },
            { title: '6502', viewSrc: 'Lv64-360-Morning-SE' },
            { title: '6602', viewSrc: 'Lv67-360-Morning-SE' },
            { title: '6702', viewSrc: 'Lv67-360-Morning-SE' },
            { title: '6802', viewSrc: 'Lv69-360-Morning-SE' },
            { title: '6902', viewSrc: 'Lv69-360-Morning-SE' },
            { title: '7002', viewSrc: 'Lv69-360-Morning-SE' },
        ],
        'H2AS.2':[
            { title: '5302', viewSrc: 'Lv52-360-Morning-SE' },
            { title: '5402', viewSrc: 'Lv55-360-Morning-SE' },
        ],
        'H2CS.1':[
            { title: '5008', viewSrc: 'Lv49-360-Morning-NW' },
        ],
        'H2CS.2':[
            { title: '5108', viewSrc: 'Lv52-360-Morning-NW' },
            { title: '5208', viewSrc: 'Lv52-360-Morning-NW' },
            { title: '5308', viewSrc: 'Lv52-360-Morning-NW' },
            { title: '5408', viewSrc: 'Lv55-360-Morning-NW' },
            { title: '5508', viewSrc: 'Lv55-360-Morning-NW' },
            { title: '5608', viewSrc: 'Lv55-360-Morning-NW' },
            { title: '5708', viewSrc: 'Lv58-360-Morning-NW' },
            { title: '5808', viewSrc: 'Lv58-360-Morning-NW' },
            { title: '5908', viewSrc: 'Lv58-360-Morning-NW' },
            { title: '6008', viewSrc: 'Lv61-360-Morning-NW' },
            { title: '6108', viewSrc: 'Lv61-360-Morning-NW' },
            { title: '6208', viewSrc: 'Lv61-360-Morning-NW' },
            { title: '6308', viewSrc: 'Lv64-360-Morning-NW' },
            { title: '6408', viewSrc: 'Lv64-360-Morning-NW' },
            { title: '6508', viewSrc: 'Lv64-360-Morning-NW' },
            { title: '6608', viewSrc: 'Lv67-360-Morning-NW' },
            { title: '6708', viewSrc: 'Lv67-360-Morning-NW' },
            { title: '6808', viewSrc: 'Lv69-360-Morning-NW' },
            { title: '6908', viewSrc: 'Lv69-360-Morning-NW' },
            { title: '7008', viewSrc: 'Lv69-360-Morning-NW' },
        ],
        'H2DS.1':[
            { title: '5009', viewSrc: 'Lv49-360-Morning-N' },
            { title: '5109', viewSrc: 'Lv52-360-Morning-N' },
            { title: '5209', viewSrc: 'Lv52-360-Morning-N' },
            { title: '5309', viewSrc: 'Lv52-360-Morning-N' },
            { title: '5409', viewSrc: 'Lv55-360-Morning-N' },
            { title: '5509', viewSrc: 'Lv55-360-Morning-N' },
            { title: '5609', viewSrc: 'Lv55-360-Morning-N' },
            { title: '5709', viewSrc: 'Lv58-360-Morning-N' },
            { title: '5809', viewSrc: 'Lv58-360-Morning-N' },
            { title: '5909', viewSrc: 'Lv58-360-Morning-N' },
            { title: '6009', viewSrc: 'Lv61-360-Morning-N' },
            { title: '6109', viewSrc: 'Lv61-360-Morning-N' },
            { title: '6209', viewSrc: 'Lv61-360-Morning-N' },
            { title: '6309', viewSrc: 'Lv64-360-Morning-N' },
            { title: '6409', viewSrc: 'Lv64-360-Morning-N' },
            { title: '6509', viewSrc: 'Lv64-360-Morning-N' },
            { title: '6609', viewSrc: 'Lv67-360-Morning-N' },
            { title: '6709', viewSrc: 'Lv67-360-Morning-N' },
            { title: '6809', viewSrc: 'Lv69-360-Morning-N' },
            { title: '6909', viewSrc: 'Lv69-360-Morning-N' },
            { title: '7009', viewSrc: 'Lv69-360-Morning-N' },
        ],
        'H2ES.1':[
            { title: '5010', viewSrc: 'Lv49-360-Morning-NE' },
        ],
        'H2FS.1':[
            { title: '5102', viewSrc: 'Lv52-360-Morning-SE' },
            { title: '5202', viewSrc: 'Lv52-360-Morning-SE' },
        ],
        'H2FS.2':[
            { title: '5602', viewSrc: 'Lv55-360-Morning-SE' },
            { title: '5702', viewSrc: 'Lv58-360-Morning-SE' },
        ],
        'H2GS.1':[
            { title: '5110', viewSrc: 'Lv52-360-Morning-NE' },
            { title: '5210', viewSrc: 'Lv52-360-Morning-NE' },
            { title: '5310', viewSrc: 'Lv52-360-Morning-NE' },
            { title: '5410', viewSrc: 'Lv55-360-Morning-NE' },
        ],
        'H2HS.1':[
            { title: '5510', viewSrc: 'Lv55-360-Morning-NE' },
            { title: '5610', viewSrc: 'Lv55-360-Morning-NE' },
            { title: '5710', viewSrc: 'Lv58-360-Morning-NE' },
            { title: '5810', viewSrc: 'Lv58-360-Morning-NE' },
            { title: '5910', viewSrc: 'Lv58-360-Morning-NE' },
            { title: '6010', viewSrc: 'Lv61-360-Morning-NE' },
            { title: '6110', viewSrc: 'Lv61-360-Morning-NE' },
            { title: '6210', viewSrc: 'Lv61-360-Morning-NE' },
            { title: '6310', viewSrc: 'Lv64-360-Morning-NE' },
        ],
        'H2HS.2':[
            { title: '6410', viewSrc: 'Lv64-360-Morning-NE' },
            { title: '6510', viewSrc: 'Lv64-360-Morning-NE' },
            { title: '6610', viewSrc: 'Lv67-360-Morning-NE' },
            { title: '6710', viewSrc: 'Lv67-360-Morning-NE' },
            { title: '6810', viewSrc: 'Lv69-360-Morning-NE' },
            { title: '6910', viewSrc: 'Lv69-360-Morning-NE' },
            { title: '7010', viewSrc: 'Lv69-360-Morning-NE' },
        ],
        'H2JS.1':[
            { title: '5007', viewSrc: 'Lv49-360-Morning-NW' },
            { title: '5107', viewSrc: 'Lv52-360-Morning-NW' },
            { title: '5207', viewSrc: 'Lv52-360-Morning-NW' },
            { title: '5307', viewSrc: 'Lv52-360-Morning-NW' },
            { title: '5407', viewSrc: 'Lv55-360-Morning-NW' },
            { title: '5507', viewSrc: 'Lv55-360-Morning-NW' },
            { title: '5607', viewSrc: 'Lv55-360-Morning-NW' },
            { title: '5707', viewSrc: 'Lv58-360-Morning-NW' },
            { title: '5807', viewSrc: 'Lv58-360-Morning-NW' },
            { title: '5907', viewSrc: 'Lv58-360-Morning-NW' },
            { title: '6007', viewSrc: 'Lv61-360-Morning-NW' },
            { title: '6107', viewSrc: 'Lv61-360-Morning-NW' },
            { title: '6207', viewSrc: 'Lv61-360-Morning-NW' },
            { title: '6307', viewSrc: 'Lv64-360-Morning-NW' },
            { title: '6407', viewSrc: 'Lv64-360-Morning-NW' },
            { title: '6507', viewSrc: 'Lv64-360-Morning-NW' },
            { title: '6607', viewSrc: 'Lv67-360-Morning-NW' },
            { title: '6707', viewSrc: 'Lv67-360-Morning-NW' },
            { title: '6807', viewSrc: 'Lv69-360-Morning-NW' },
            { title: '6907', viewSrc: 'Lv69-360-Morning-NW' },
            { title: '7007', viewSrc: 'Lv69-360-Morning-NW' },
        ],
        'H3AS.1':[
            { title: '4901', viewSrc: 'Lv49-360-Morning-E' },
            { title: '5001', viewSrc: 'Lv49-360-Morning-E' },
            { title: '5101', viewSrc: 'Lv52-360-Morning-E' },
            { title: '5201', viewSrc: 'Lv52-360-Morning-E' },
            { title: '5301', viewSrc: 'Lv52-360-Morning-E' },
            { title: '5401', viewSrc: 'Lv55-360-Morning-E' },
            { title: '5501', viewSrc: 'Lv55-360-Morning-E' },
            { title: '5601', viewSrc: 'Lv55-360-Morning-E' },
            { title: '5701', viewSrc: 'Lv58-360-Morning-E' },
            { title: '5801', viewSrc: 'Lv58-360-Morning-E' },
            { title: '5901', viewSrc: 'Lv58-360-Morning-E' },
            { title: '6001', viewSrc: 'Lv61-360-Morning-E' },
            { title: '6101', viewSrc: 'Lv61-360-Morning-E' },
            { title: '6201', viewSrc: 'Lv61-360-Morning-E' },
            { title: '6301', viewSrc: 'Lv64-360-Morning-E' },
            { title: '6401', viewSrc: 'Lv64-360-Morning-E' },
            { title: '6501', viewSrc: 'Lv64-360-Morning-E' },
            { title: '6601', viewSrc: 'Lv67-360-Morning-E' },
            { title: '6701', viewSrc: 'Lv67-360-Morning-E' },
            { title: '6801', viewSrc: 'Lv69-360-Morning-E' },
            { title: '6901', viewSrc: 'Lv69-360-Morning-E' },
            { title: '7001', viewSrc: 'Lv69-360-Morning-E' },
        ],
        'H3BS.1':[
            { title: '4903', viewSrc: 'Lv49-360-Morning-S' },
            { title: '5003', viewSrc: 'Lv49-360-Morning-S' },
            { title: '5303', viewSrc: 'Lv52-360-Morning-S' },
            { title: '5403', viewSrc: 'Lv55-360-Morning-S' },
            { title: '5503', viewSrc: 'Lv55-360-Morning-S' },
            { title: '5803', viewSrc: 'Lv58-360-Morning-S' },
            { title: '5903', viewSrc: 'Lv58-360-Morning-S' },
            { title: '6003', viewSrc: 'Lv61-360-Morning-S' },
            { title: '6103', viewSrc: 'Lv61-360-Morning-S' },
            { title: '6203', viewSrc: 'Lv61-360-Morning-S' },
            { title: '6303', viewSrc: 'Lv64-360-Morning-S' },
            { title: '6403', viewSrc: 'Lv64-360-Morning-S' },
            { title: '6503', viewSrc: 'Lv64-360-Morning-S' },
            { title: '6603', viewSrc: 'Lv67-360-Morning-S' },
            { title: '6703', viewSrc: 'Lv67-360-Morning-S' },
            { title: '6803', viewSrc: 'Lv69-360-Morning-S' },
            { title: '6903', viewSrc: 'Lv69-360-Morning-S' },
            { title: '7003', viewSrc: 'Lv69-360-Morning-S' },
        ],
        'H3DS.1':[
            { title: '5103', viewSrc: 'Lv52-360-Morning-S' },
            { title: '5203', viewSrc: 'Lv52-360-Morning-S' },
            { title: '5603', viewSrc: 'Lv55-360-Morning-S' },
            { title: '5703', viewSrc: 'Lv58-360-Morning-S' },
        ],
        'H3ES.1':[
            { title: '5006', viewSrc: 'Lv49-360-Morning-W' },
            { title: '5106', viewSrc: 'Lv52-360-Morning-W' },
            { title: '5206', viewSrc: 'Lv52-360-Morning-W' },
            { title: '5306', viewSrc: 'Lv52-360-Morning-W' },
            { title: '5406', viewSrc: 'Lv55-360-Morning-W' },
            { title: '5506', viewSrc: 'Lv55-360-Morning-W' },
            { title: '5606', viewSrc: 'Lv55-360-Morning-W' },
            { title: '5706', viewSrc: 'Lv58-360-Morning-W' },
            { title: '5806', viewSrc: 'Lv58-360-Morning-W' },
            { title: '5906', viewSrc: 'Lv58-360-Morning-W' },
            { title: '6006', viewSrc: 'Lv61-360-Morning-W' },
            { title: '6106', viewSrc: 'Lv61-360-Morning-W' },
            { title: '6206', viewSrc: 'Lv61-360-Morning-W' },
            { title: '6306', viewSrc: 'Lv64-360-Morning-W' },
            { title: '6406', viewSrc: 'Lv64-360-Morning-W' },
            { title: '6506', viewSrc: 'Lv64-360-Morning-W' },
            { title: '6606', viewSrc: 'Lv67-360-Morning-W' },
            { title: '6706', viewSrc: 'Lv67-360-Morning-W' },
            { title: '6806', viewSrc: 'Lv69-360-Morning-W' },
            { title: '6906', viewSrc: 'Lv69-360-Morning-W' },
            { title: '7006', viewSrc: 'Lv69-360-Morning-W' },
        ],
        'M1AS.1':[
            { title: '3106', viewSrc: 'Lv31-360-Morning-SW' },
            { title: '3113', viewSrc: 'Lv31-360-Morning-NE' },
            { title: '3206', viewSrc: 'Lv31-360-Morning-SW' },
            { title: '3213', viewSrc: 'Lv31-360-Morning-NE' },
            { title: '3306', viewSrc: 'Lv34-360-Morning-SW' },
            { title: '3313', viewSrc: 'Lv34-360-Morning-NE' },
            { title: '3406', viewSrc: 'Lv34-360-Morning-SW' },
            { title: '3413', viewSrc: 'Lv34-360-Morning-NE' },
            { title: '3506', viewSrc: 'Lv34-360-Morning-SW' },
            { title: '3513', viewSrc: 'Lv34-360-Morning-NE' },
        ],
        'M1AS.2':[
            { title: '3606', viewSrc: 'Lv37-360-Morning-SW' },
            { title: '3613', viewSrc: 'Lv37-360-Morning-NE' },
            { title: '3706', viewSrc: 'Lv37-360-Morning-SW' },
            { title: '3713', viewSrc: 'Lv37-360-Morning-NE' },
            { title: '3806', viewSrc: 'Lv37-360-Morning-SW' },
            { title: '3813', viewSrc: 'Lv37-360-Morning-NE' },
            { title: '4106', viewSrc: 'Lv40-360-Morning-SW' },
            { title: '4113', viewSrc: 'Lv40-360-Morning-NE' },
            { title: '4206', viewSrc: 'Lv43-360-Morning-SW' },
            { title: '4213', viewSrc: 'Lv43-360-Morning-NE' },
        ],
        'M1AS.3':[
            { title: '4306', viewSrc: 'Lv43-360-Morning-SW' },
            { title: '4313', viewSrc: 'Lv43-360-Morning-NE' },
            { title: '4406', viewSrc: 'Lv43-360-Morning-SW' },
            { title: '4413', viewSrc: 'Lv43-360-Morning-NE' },
            { title: '4506', viewSrc: 'Lv46-360-Morning-SW' },
            { title: '4513', viewSrc: 'Lv46-360-Morning-NE' },
            { title: '4606', viewSrc: 'Lv46-360-Morning-SW' },
            { title: '4613', viewSrc: 'Lv46-360-Morning-NE' },
            { title: '4706', viewSrc: 'Lv46-360-Morning-SW' },
            { title: '4713', viewSrc: 'Lv46-360-Morning-NE' },
            { title: '4806', viewSrc: 'Lv48-360-Morning-SW' },
            { title: '4812', viewSrc: 'Lv48-360-Morning-NE' },
        ],
        'M1BS.1':[
            { title: '3101', viewSrc: 'Lv31-360-Morning-NE' },
            { title: '3107', viewSrc: 'Lv31-360-Morning-SW' },
            { title: '3201', viewSrc: 'Lv31-360-Morning-NE' },
            { title: '3207', viewSrc: 'Lv31-360-Morning-SW' },
            { title: '3301', viewSrc: 'Lv34-360-Morning-NE' },
            { title: '3307', viewSrc: 'Lv34-360-Morning-SW' },
            { title: '3401', viewSrc: 'Lv34-360-Morning-NE' },
            { title: '3407', viewSrc: 'Lv34-360-Morning-SW' },
            { title: '3501', viewSrc: 'Lv34-360-Morning-NE' },
            { title: '3507', viewSrc: 'Lv34-360-Morning-SW' },
        ],
        'M1BS.2':[
            { title: '3601', viewSrc: 'Lv37-360-Morning-NE' },
            { title: '3607', viewSrc: 'Lv37-360-Morning-SW' },
            { title: '3701', viewSrc: 'Lv37-360-Morning-NE' },
            { title: '3707', viewSrc: 'Lv37-360-Morning-SW' },
            { title: '3801', viewSrc: 'Lv37-360-Morning-NE' },
            { title: '3807', viewSrc: 'Lv37-360-Morning-SW' },
            { title: '4101', viewSrc: 'Lv40-360-Morning-NE' },
            { title: '4107', viewSrc: 'Lv40-360-Morning-SW' },
            { title: '4201', viewSrc: 'Lv43-360-Morning-NE' },
            { title: '4207', viewSrc: 'Lv43-360-Morning-SW' },
        ],
        'M1BS.3':[
            { title: '4301', viewSrc: 'Lv43-360-Morning-NE' },
            { title: '4307', viewSrc: 'Lv43-360-Morning-SW' },
            { title: '4401', viewSrc: 'Lv43-360-Morning-NE' },
            { title: '4407', viewSrc: 'Lv43-360-Morning-SW' },
            { title: '4501', viewSrc: 'Lv46-360-Morning-NE' },
            { title: '4507', viewSrc: 'Lv46-360-Morning-SW' },
            { title: '4601', viewSrc: 'Lv46-360-Morning-NE' },
            { title: '4607', viewSrc: 'Lv46-360-Morning-SW' },
            { title: '4701', viewSrc: 'Lv46-360-Morning-NE' },
            { title: '4707', viewSrc: 'Lv46-360-Morning-SW' },
            { title: '4801', viewSrc: 'Lv48-360-Morning-NE' },
            { title: '4807', viewSrc: 'Lv48-360-Morning-SW' },
        ],
        'M1CS.1':[
            { title: '3110', viewSrc: 'Lv31-360-Morning-NW' },
            { title: '3210', viewSrc: 'Lv31-360-Morning-NW' },
            { title: '3310', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3410', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3510', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3610', viewSrc: 'Lv37-360-Morning-NW' },
        ],
        'M1CS.2':[
            { title: '3710', viewSrc: 'Lv37-360-Morning-NW' },
        ],
        'M1CS.3':[
            { title: '3810', viewSrc: 'Lv37-360-Morning-NW' },
        ],
        'M1DS.1':[
            { title: '3111', viewSrc: 'Lv31-360-Morning-NW' },
            { title: '3211', viewSrc: 'Lv31-360-Morning-NW' },
            { title: '3311', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3411', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3511', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3611', viewSrc: 'Lv37-360-Morning-NW' },
        ],
        'M1DS.2':[
            { title: '3711', viewSrc: 'Lv37-360-Morning-NW' },
            { title: '3811', viewSrc: 'Lv37-360-Morning-NW' },
        ],
        'M1DS.3':[
            { title: '3911', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4011', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4111', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4211', viewSrc: 'Lv43-360-Morning-NW' },
            { title: '4311', viewSrc: 'Lv43-360-Morning-NW' },
            { title: '4411', viewSrc: 'Lv43-360-Morning-NW' },
        ],
        'M1DS.4':[
            { title: '4511', viewSrc: 'Lv46-360-Morning-NW' },
            { title: '4611', viewSrc: 'Lv46-360-Morning-NW' },
            { title: '4711', viewSrc: 'Lv46-360-Morning-NW' },
            { title: '4810', viewSrc: 'Lv48-360-Morning-NW' },
        ],
        'M1ES.1':[
            { title: '3906', viewSrc: 'Lv40-360-Morning-SW' },
            { title: '3913', viewSrc: 'Lv40-360-Morning-NE' },
            { title: '4006', viewSrc: 'Lv40-360-Morning-SW' },
            { title: '4013', viewSrc: 'Lv40-360-Morning-NE' },
        ],
        'M1FS.1':[
            { title: '3910', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4010', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4110', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4210', viewSrc: 'Lv43-360-Morning-NW' },
            { title: '4310', viewSrc: 'Lv43-360-Morning-NW' },
        ],
        'M1FS.2':[
            { title: '4410', viewSrc: 'Lv43-360-Morning-NW' },
        ],
        'M1GS.1':[
            { title: '3901', viewSrc: 'Lv40-360-Morning-NE' },
            { title: '3907', viewSrc: 'Lv40-360-Morning-SW' },
            { title: '4001', viewSrc: 'Lv40-360-Morning-NE' },
            { title: '4007', viewSrc: 'Lv40-360-Morning-SW' },
        ],
        'M1HS.1':[
            { title: '4510', viewSrc: 'Lv46-360-Morning-NW' },
            { title: '4610', viewSrc: 'Lv46-360-Morning-NW' },
            { title: '4710', viewSrc: 'Lv46-360-Morning-NW' },
        ],
        'M2AS.1':[
            { title: '3102', viewSrc: 'Lv31-360-Morning-E' },
            { title: '3108', viewSrc: 'Lv31-360-Morning-W' },
            { title: '3202', viewSrc: 'Lv31-360-Morning-E' },
            { title: '3208', viewSrc: 'Lv31-360-Morning-W' },
            { title: '3302', viewSrc: 'Lv34-360-Morning-E' },
            { title: '3308', viewSrc: 'Lv34-360-Morning-W' },
            { title: '3402', viewSrc: 'Lv34-360-Morning-E' },
            { title: '3408', viewSrc: 'Lv34-360-Morning-W' },
            { title: '3502', viewSrc: 'Lv34-360-Morning-E' },
            { title: '3508', viewSrc: 'Lv34-360-Morning-W' },
            { title: '3602', viewSrc: 'Lv37-360-Morning-E' },
            { title: '3608', viewSrc: 'Lv37-360-Morning-W' },
        ],
        'M2AS.2':[
            { title: '3702', viewSrc: 'Lv37-360-Morning-E' },
            { title: '3708', viewSrc: 'Lv37-360-Morning-W' },
            { title: '3802', viewSrc: 'Lv37-360-Morning-E' },
            { title: '3808', viewSrc: 'Lv37-360-Morning-W' },
            { title: '4102', viewSrc: 'Lv40-360-Morning-E' },
            { title: '4108', viewSrc: 'Lv40-360-Morning-W' },
            { title: '4202', viewSrc: 'Lv43-360-Morning-E' },
            { title: '4208', viewSrc: 'Lv43-360-Morning-W' },
            { title: '4302', viewSrc: 'Lv43-360-Morning-E' },
            { title: '4308', viewSrc: 'Lv43-360-Morning-W' },
            { title: '4402', viewSrc: 'Lv43-360-Morning-E' },
            { title: '4408', viewSrc: 'Lv43-360-Morning-W' },
            { title: '4502', viewSrc: 'Lv46-360-Morning-E' },
            { title: '4508', viewSrc: 'Lv46-360-Morning-W' },
            { title: '4602', viewSrc: 'Lv46-360-Morning-E' },
            { title: '4608', viewSrc: 'Lv46-360-Morning-W' },
            { title: '4702', viewSrc: 'Lv46-360-Morning-E' },
            { title: '4708', viewSrc: 'Lv46-360-Morning-W' },
            { title: '4802', viewSrc: 'Lv48-360-Morning-E' },
        ],
        'M2BS.1':[
            { title: '3103', viewSrc: 'Lv31-360-Morning-SE' },
            { title: '3203', viewSrc: 'Lv31-360-Morning-SE' },
            { title: '3303', viewSrc: 'Lv34-360-Morning-SE' },
            { title: '3403', viewSrc: 'Lv34-360-Morning-SE' },
            { title: '3503', viewSrc: 'Lv34-360-Morning-SE' },
            { title: '3603', viewSrc: 'Lv37-360-Morning-SE' },
        ],
        'M2BS.2':[
            { title: '3703', viewSrc: 'Lv37-360-Morning-SE' },
            { title: '3803', viewSrc: 'Lv37-360-Morning-SE' },
            { title: '3903', viewSrc: 'Lv40-360-Morning-SE' },
            { title: '4003', viewSrc: 'Lv40-360-Morning-SE' },
            { title: '4103', viewSrc: 'Lv40-360-Morning-SE' },
            { title: '4203', viewSrc: 'Lv43-360-Morning-SE' },
            { title: '4303', viewSrc: 'Lv43-360-Morning-SE' },
            { title: '4403', viewSrc: 'Lv43-360-Morning-SE' },
            { title: '4503', viewSrc: 'Lv46-360-Morning-SE' },
            { title: '4603', viewSrc: 'Lv46-360-Morning-SE' },
            { title: '4703', viewSrc: 'Lv46-360-Morning-SE' },
            { title: '4803', viewSrc: 'Lv48-360-Morning-SE' },
        ],
        'M2CS.1':[
            { title: '3104', viewSrc: 'Lv31-360-Morning-SE' },
            { title: '3204', viewSrc: 'Lv31-360-Morning-SE' },
            { title: '3304', viewSrc: 'Lv34-360-Morning-SE' },
            { title: '3404', viewSrc: 'Lv34-360-Morning-SE' },
            { title: '3504', viewSrc: 'Lv34-360-Morning-SE' },
            { title: '3604', viewSrc: 'Lv37-360-Morning-SE' },
        ],
        'M2CS.2':[
            { title: '3704', viewSrc: 'Lv37-360-Morning-SE' },
            { title: '3804', viewSrc: 'Lv37-360-Morning-SE' },
            { title: '3904', viewSrc: 'Lv40-360-Morning-SE' },
            { title: '4004', viewSrc: 'Lv40-360-Morning-SE' },
            { title: '4104', viewSrc: 'Lv40-360-Morning-SE' },
            { title: '4204', viewSrc: 'Lv43-360-Morning-SE' },
            { title: '4304', viewSrc: 'Lv43-360-Morning-SE' },
            { title: '4404', viewSrc: 'Lv43-360-Morning-SE' },
            { title: '4504', viewSrc: 'Lv46-360-Morning-SE' },
            { title: '4604', viewSrc: 'Lv46-360-Morning-SE' },
            { title: '4704', viewSrc: 'Lv46-360-Morning-SE' },
            { title: '4804', viewSrc: 'Lv48-360-Morning-SE' },
        ],
        'M2DS.1':[
            { title: '3105', viewSrc: 'Lv31-360-Morning-S' },
            { title: '3205', viewSrc: 'Lv31-360-Morning-S' },
            { title: '3305', viewSrc: 'Lv34-360-Morning-S' },
            { title: '3405', viewSrc: 'Lv34-360-Morning-S' },
            { title: '3505', viewSrc: 'Lv34-360-Morning-S' },
            { title: '3605', viewSrc: 'Lv37-360-Morning-S' },
        ],
        'M2DS.2':[
            { title: '3705', viewSrc: 'Lv37-360-Morning-S' },
            { title: '3805', viewSrc: 'Lv37-360-Morning-S' },
            { title: '4105', viewSrc: 'Lv40-360-Morning-S' },
            { title: '4205', viewSrc: 'Lv43-360-Morning-S' },
            { title: '4305', viewSrc: 'Lv43-360-Morning-S' },
            { title: '4405', viewSrc: 'Lv43-360-Morning-S' },
            { title: '4505', viewSrc: 'Lv46-360-Morning-S' },
            { title: '4605', viewSrc: 'Lv46-360-Morning-S' },
            { title: '4705', viewSrc: 'Lv46-360-Morning-S' },
            { title: '4805', viewSrc: 'Lv48-360-Morning-S' },
        ],
        'M2ES.1':[
            { title: '3109', viewSrc: 'Lv31-360-Morning-NW' },
            { title: '3209', viewSrc: 'Lv31-360-Morning-NW' },
            { title: '3309', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3409', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3509', viewSrc: 'Lv34-360-Morning-NW' },
            { title: '3609', viewSrc: 'Lv37-360-Morning-NW' },
        ],
        'M2ES.2':[
            { title: '3709', viewSrc: 'Lv37-360-Morning-NW' },
            { title: '3809', viewSrc: 'Lv37-360-Morning-NW' },
            { title: '3909', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4009', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4109', viewSrc: 'Lv40-360-Morning-NW' },
            { title: '4209', viewSrc: 'Lv43-360-Morning-NW' },
            { title: '4309', viewSrc: 'Lv43-360-Morning-NW' },
            { title: '4409', viewSrc: 'Lv43-360-Morning-NW' },
        ],
        'M2ES.3':[
            { title: '4509', viewSrc: 'Lv46-360-Morning-NW' },
        ],
        'M2FS.1':[
            { title: '3112', viewSrc: 'Lv31-360-Morning-N' },
            { title: '3212', viewSrc: 'Lv31-360-Morning-N' },
            { title: '3312', viewSrc: 'Lv34-360-Morning-N' },
            { title: '3412', viewSrc: 'Lv34-360-Morning-N' },
            { title: '3512', viewSrc: 'Lv34-360-Morning-N' },
        ],
        'M2FS.2':[
            { title: '4312', viewSrc: 'Lv43-360-Morning-N' },
            { title: '4412', viewSrc: 'Lv43-360-Morning-N' },
            { title: '4512', viewSrc: 'Lv46-360-Morning-N' },
            { title: '4612', viewSrc: 'Lv46-360-Morning-N' },
            { title: '4712', viewSrc: 'Lv46-360-Morning-N' },
            { title: '4811', viewSrc: 'Lv48-360-Morning-N' },
        ],
        'M2GS.1':[
            { title: '3612', viewSrc: 'Lv37-360-Morning-N' },
        ],
        'M2GS.2':[
            { title: '3712', viewSrc: 'Lv37-360-Morning-N' },
            { title: '3812', viewSrc: 'Lv37-360-Morning-N' },
            { title: '4112', viewSrc: 'Lv40-360-Morning-N' },
            { title: '4212', viewSrc: 'Lv43-360-Morning-N' },
        ],
        'M2HS.1':[
            { title: '3902', viewSrc: 'Lv40-360-Morning-E' },
            { title: '3908', viewSrc: 'Lv40-360-Morning-W' },
            { title: '4002', viewSrc: 'Lv40-360-Morning-E' },
            { title: '4008', viewSrc: 'Lv40-360-Morning-W' },
        ],
        'M2JS.1':[
            { title: '3905', viewSrc: 'Lv40-360-Morning-S' },
            { title: '4005', viewSrc: 'Lv40-360-Morning-S' },
        ],
        'M2KS.1':[
            { title: '3912', viewSrc: 'Lv40-360-Morning-N' },
            { title: '4012', viewSrc: 'Lv40-360-Morning-N' },
        ],
        'M2LS.1':[
            { title: '4609', viewSrc: 'Lv46-360-Morning-NW' },
            { title: '4709', viewSrc: 'Lv46-360-Morning-NW' },
        ],
        'M2MS.1':[
            { title: '4809', viewSrc: 'Lv48-360-Morning-NW' },
        ],
        'M3AS.1':[
            { title: '4808', viewSrc: 'Lv48-360-Morning-W' },
        ],
        'L1AM.1':[
            { title: '806', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '906', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '1006', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1106', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1206', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1306', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1406', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1506', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1606', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1706', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1806', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1906', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2006', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2106', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2206', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2306', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2406', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2506', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2606', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2706', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2806', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '2906', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '3006', viewSrc: 'Lv30-360-Morning-SE' },
        ],
        'L1AM.2':[
            { title: '814', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '914', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '1014', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1114', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1214', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1314', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1414', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1514', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1614', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1714', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1814', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1914', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2014', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2114', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2214', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2314', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2414', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2514', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2614', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2714', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2814', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '2914', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '3014', viewSrc: 'Lv30-360-Morning-NW' },
        ],
        'L1AS.1':[
            { title: '803', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '811', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '903', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '911', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '1003', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1011', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1103', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1111', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1203', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1211', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1303', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1311', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1403', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1411', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1503', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1511', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1603', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1611', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1703', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1711', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1803', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1811', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1903', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '1911', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2003', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2011', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2103', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2111', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2203', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2211', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2303', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2311', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2403', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2411', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2503', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2511', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2603', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2611', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2703', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2711', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2803', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '2811', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '2903', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '2911', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '3003', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '3011', viewSrc: 'Lv30-360-Morning-NW' },
        ],
        'L1BM.1':[
            { title: '805', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '905', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '1005', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1105', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1205', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1305', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1405', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1505', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1605', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1705', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1805', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1905', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2005', viewSrc: 'Lv20-360-Morning-SE' },
        ],
        'L1BM.2':[
            { title: '2105', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2205', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2305', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2405', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2505', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2605', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2705', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2805', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '2905', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '3005', viewSrc: 'Lv30-360-Morning-SE' },
        ],
        'L1BS.1':[
            { title: '804', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '904', viewSrc: 'Lv8-360-Morning-SE' },
            { title: '1004', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1104', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1204', viewSrc: 'Lv11-360-Morning-SE' },
            { title: '1304', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1404', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1504', viewSrc: 'Lv14-360-Morning-SE' },
            { title: '1604', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1704', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1804', viewSrc: 'Lv17-360-Morning-SE' },
            { title: '1904', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2004', viewSrc: 'Lv20-360-Morning-SE' },
        ],
        'L1BS.2':[
            { title: '2104', viewSrc: 'Lv20-360-Morning-SE' },
            { title: '2204', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2304', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2404', viewSrc: 'Lv23-360-Morning-SE' },
            { title: '2504', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2604', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2704', viewSrc: 'Lv26-360-Morning-SE' },
            { title: '2804', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '2904', viewSrc: 'Lv30-360-Morning-SE' },
            { title: '3004', viewSrc: 'Lv30-360-Morning-SE' },
        ],
        'L1CS.1':[
            { title: '801', viewSrc: 'Lv8-360-Morning-NE' },
            { title: '809', viewSrc: 'Lv8-360-Morning-SW2' },
            { title: '901', viewSrc: 'Lv8-360-Morning-NE' },
            { title: '909', viewSrc: 'Lv8-360-Morning-SW2' },
            { title: '1001', viewSrc: 'Lv11-360-Morning-NE' },
            { title: '1009', viewSrc: 'Lv11-360-Morning-SW2' },
            { title: '1101', viewSrc: 'Lv11-360-Morning-NE' },
            { title: '1109', viewSrc: 'Lv11-360-Morning-SW2' },
            { title: '1201', viewSrc: 'Lv11-360-Morning-NE' },
            { title: '1209', viewSrc: 'Lv11-360-Morning-SW2' },
        ],
        'L1CS.2':[
            { title: '1301', viewSrc: 'Lv14-360-Morning-NE' },
            { title: '1309', viewSrc: 'Lv14-360-Morning-SW2' },
            { title: '1401', viewSrc: 'Lv14-360-Morning-NE' },
            { title: '1409', viewSrc: 'Lv14-360-Morning-SW2' },
            { title: '1501', viewSrc: 'Lv14-360-Morning-NE' },
            { title: '1509', viewSrc: 'Lv14-360-Morning-SW2' },
            { title: '1801', viewSrc: 'Lv17-360-Morning-NE' },
            { title: '1809', viewSrc: 'Lv17-360-Morning-SW2' },
            { title: '1901', viewSrc: 'Lv20-360-Morning-NE' },
            { title: '1909', viewSrc: 'Lv20-360-Morning-SW2' },
            { title: '2001', viewSrc: 'Lv20-360-Morning-NE' },
            { title: '2009', viewSrc: 'Lv20-360-Morning-SW2' },
        ],
        'L1CS.3':[
            { title: '2101', viewSrc: 'Lv20-360-Morning-NE' },
            { title: '2109', viewSrc: 'Lv20-360-Morning-SW2' },
            { title: '2201', viewSrc: 'Lv23-360-Morning-NE' },
            { title: '2209', viewSrc: 'Lv23-360-Morning-SW2' },
            { title: '2301', viewSrc: 'Lv23-360-Morning-NE' },
            { title: '2309', viewSrc: 'Lv23-360-Morning-SW2' },
            { title: '2401', viewSrc: 'Lv23-360-Morning-NE' },
            { title: '2409', viewSrc: 'Lv23-360-Morning-SW2' },
            { title: '2501', viewSrc: 'Lv26-360-Morning-NE' },
            { title: '2509', viewSrc: 'Lv26-360-Morning-SW2' },
            { title: '2601', viewSrc: 'Lv26-360-Morning-NE' },
            { title: '2609', viewSrc: 'Lv26-360-Morning-SW2' },
            { title: '2701', viewSrc: 'Lv26-360-Morning-NE' },
            { title: '2709', viewSrc: 'Lv26-360-Morning-SW2' },
            { title: '2801', viewSrc: 'Lv30-360-Morning-NE' },
            { title: '2809', viewSrc: 'Lv30-360-Morning-SW2' },
            { title: '2901', viewSrc: 'Lv30-360-Morning-NE' },
            { title: '2909', viewSrc: 'Lv30-360-Morning-SW2' },
            { title: '3001', viewSrc: 'Lv30-360-Morning-NE' },
            { title: '3009', viewSrc: 'Lv30-360-Morning-SW2' },
        ],
        'L1DS.1':[
            { title: '808', viewSrc: 'Lv8-360-Morning-SW2' },
            { title: '816', viewSrc: 'Lv8-360-Morning-NE' },
            { title: '908', viewSrc: 'Lv8-360-Morning-SW2' },
            { title: '916', viewSrc: 'Lv8-360-Morning-NE' },
            { title: '1008', viewSrc: 'Lv11-360-Morning-SW2' },
            { title: '1016', viewSrc: 'Lv11-360-Morning-NE' },
            { title: '1108', viewSrc: 'Lv11-360-Morning-SW2' },
            { title: '1116', viewSrc: 'Lv11-360-Morning-NE' },
            { title: '1208', viewSrc: 'Lv11-360-Morning-SW2' },
            { title: '1216', viewSrc: 'Lv11-360-Morning-NE' },
        ],
        'L1DS.2':[
            { title: '1308', viewSrc: 'Lv14-360-Morning-SW2' },
            { title: '1316', viewSrc: 'Lv14-360-Morning-NE' },
            { title: '1408', viewSrc: 'Lv14-360-Morning-SW2' },
            { title: '1416', viewSrc: 'Lv14-360-Morning-NE' },
            { title: '1508', viewSrc: 'Lv14-360-Morning-SW2' },
            { title: '1516', viewSrc: 'Lv14-360-Morning-NE' },
            { title: '1808', viewSrc: 'Lv17-360-Morning-SW2' },
            { title: '1816', viewSrc: 'Lv17-360-Morning-NE' },
            { title: '1908', viewSrc: 'Lv20-360-Morning-SW2' },
            { title: '1916', viewSrc: 'Lv20-360-Morning-NE' },
            { title: '2008', viewSrc: 'Lv20-360-Morning-SW2' },
            { title: '2016', viewSrc: 'Lv20-360-Morning-NE' },
        ],
        'L1DS.3':[
            { title: '2108', viewSrc: 'Lv20-360-Morning-SW2' },
            { title: '2116', viewSrc: 'Lv20-360-Morning-NE' },
            { title: '2208', viewSrc: 'Lv23-360-Morning-SW2' },
            { title: '2216', viewSrc: 'Lv23-360-Morning-NE' },
            { title: '2308', viewSrc: 'Lv23-360-Morning-SW2' },
            { title: '2316', viewSrc: 'Lv23-360-Morning-NE' },
            { title: '2408', viewSrc: 'Lv23-360-Morning-SW2' },
            { title: '2416', viewSrc: 'Lv23-360-Morning-NE' },
            { title: '2508', viewSrc: 'Lv26-360-Morning-SW2' },
            { title: '2516', viewSrc: 'Lv26-360-Morning-NE' },
            { title: '2608', viewSrc: 'Lv26-360-Morning-SW2' },
            { title: '2616', viewSrc: 'Lv26-360-Morning-NE' },
            { title: '2708', viewSrc: 'Lv26-360-Morning-SW2' },
            { title: '2716', viewSrc: 'Lv26-360-Morning-NE' },
            { title: '2808', viewSrc: 'Lv30-360-Morning-SW2' },
            { title: '2816', viewSrc: 'Lv30-360-Morning-NE' },
            { title: '2908', viewSrc: 'Lv30-360-Morning-SW2' },
            { title: '2916', viewSrc: 'Lv30-360-Morning-NE' },
            { title: '3008', viewSrc: 'Lv30-360-Morning-SW2' },
            { title: '3016', viewSrc: 'Lv30-360-Morning-NE' },
        ],
        'L1ES.1':[
            { title: '812', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '912', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '1012', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1112', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1212', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1612', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1712', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1812', viewSrc: 'Lv17-360-Morning-NW' },
        ],
        'L1ES.2':[
            { title: '2212', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2312', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2412', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2812', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '2912', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '3012', viewSrc: 'Lv30-360-Morning-NW' },
        ],
        'L1FS.1':[
            { title: '813', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '913', viewSrc: 'Lv8-360-Morning-NW' },
            { title: '1013', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1113', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1213', viewSrc: 'Lv11-360-Morning-NW' },
            { title: '1313', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1413', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1513', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1613', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1713', viewSrc: 'Lv17-360-Morning-NW' },
        ],
        'L1GS.1':[
            { title: '1813', viewSrc: 'Lv17-360-Morning-NW' },
            { title: '1913', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2013', viewSrc: 'Lv20-360-Morning-NW' },
        ],
        'L1GS.2':[
            { title: '2113', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2213', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2313', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2413', viewSrc: 'Lv23-360-Morning-NW' },
            { title: '2513', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2613', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2713', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2813', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '2913', viewSrc: 'Lv30-360-Morning-NW' },
            { title: '3013', viewSrc: 'Lv30-360-Morning-NW' },
        ],
        'L1HS.1':[
            { title: '1312', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1412', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1512', viewSrc: 'Lv14-360-Morning-NW' },
            { title: '1912', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2012', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2112', viewSrc: 'Lv20-360-Morning-NW' },
            { title: '2512', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2612', viewSrc: 'Lv26-360-Morning-NW' },
            { title: '2712', viewSrc: 'Lv26-360-Morning-NW' },
        ],
        'L1JS.1':[
            { title: '1601', viewSrc: 'Lv17-360-Morning-NE' },
            { title: '1609', viewSrc: 'Lv17-360-Morning-SW2' },
            { title: '1701', viewSrc: 'Lv17-360-Morning-NE' },
            { title: '1709', viewSrc: 'Lv17-360-Morning-SW2' },
        ],
        'L1KS.1':[
            { title: '1608', viewSrc: 'Lv17-360-Morning-SW2' },
            { title: '1616', viewSrc: 'Lv17-360-Morning-NE' },
            { title: '1708', viewSrc: 'Lv17-360-Morning-SW2' },
            { title: '1716', viewSrc: 'Lv17-360-Morning-NE' },
        ],
        'L2AS.1':[
            { title: '802', viewSrc: 'Lv8-360-Morning-E' },
            { title: '810', viewSrc: 'Lv8-360-Morning-W' },
            { title: '902', viewSrc: 'Lv8-360-Morning-E' },
            { title: '910', viewSrc: 'Lv8-360-Morning-W' },
            { title: '1002', viewSrc: 'Lv11-360-Morning-E' },
            { title: '1010', viewSrc: 'Lv11-360-Morning-W' },
            { title: '1102', viewSrc: 'Lv11-360-Morning-E' },
            { title: '1110', viewSrc: 'Lv11-360-Morning-W' },
            { title: '1202', viewSrc: 'Lv11-360-Morning-E' },
            { title: '1210', viewSrc: 'Lv11-360-Morning-W' },
        ],
        'L2AS.2':[
            { title: '2102', viewSrc: 'Lv20-360-Morning-E' },
            { title: '2110', viewSrc: 'Lv20-360-Morning-W' },
            { title: '2202', viewSrc: 'Lv23-360-Morning-E' },
            { title: '2210', viewSrc: 'Lv23-360-Morning-W' },
            { title: '2302', viewSrc: 'Lv23-360-Morning-E' },
            { title: '2310', viewSrc: 'Lv23-360-Morning-W' },
            { title: '2402', viewSrc: 'Lv23-360-Morning-E' },
            { title: '2410', viewSrc: 'Lv23-360-Morning-W' },
            { title: '2502', viewSrc: 'Lv26-360-Morning-E' },
            { title: '2510', viewSrc: 'Lv26-360-Morning-W' },
            { title: '2602', viewSrc: 'Lv26-360-Morning-E' },
            { title: '2610', viewSrc: 'Lv26-360-Morning-W' },
            { title: '2702', viewSrc: 'Lv26-360-Morning-E' },
            { title: '2710', viewSrc: 'Lv26-360-Morning-W' },
            { title: '2802', viewSrc: 'Lv30-360-Morning-E' },
            { title: '2810', viewSrc: 'Lv30-360-Morning-W' },
            { title: '2902', viewSrc: 'Lv30-360-Morning-E' },
            { title: '2910', viewSrc: 'Lv30-360-Morning-W' },
            { title: '3002', viewSrc: 'Lv30-360-Morning-E' },
            { title: '3010', viewSrc: 'Lv30-360-Morning-W' },
        ],
        'L2BS.1':[
            { title: '807', viewSrc: 'Lv8-360-Morning-S' },
            { title: '815', viewSrc: 'Lv8-360-Morning-N' },
            { title: '907', viewSrc: 'Lv8-360-Morning-S' },
            { title: '915', viewSrc: 'Lv8-360-Morning-N' },
            { title: '1007', viewSrc: 'Lv11-360-Morning-S' },
            { title: '1015', viewSrc: 'Lv11-360-Morning-N' },
            { title: '1107', viewSrc: 'Lv11-360-Morning-S' },
            { title: '1115', viewSrc: 'Lv11-360-Morning-N' },
            { title: '1207', viewSrc: 'Lv11-360-Morning-S' },
            { title: '1215', viewSrc: 'Lv11-360-Morning-N' },
        ],
        'L2BS.2':[
            { title: '2107', viewSrc: 'Lv20-360-Morning-S' },
            { title: '2115', viewSrc: 'Lv20-360-Morning-N' },
            { title: '2207', viewSrc: 'Lv23-360-Morning-S' },
            { title: '2215', viewSrc: 'Lv23-360-Morning-N' },
            { title: '2307', viewSrc: 'Lv23-360-Morning-S' },
            { title: '2315', viewSrc: 'Lv23-360-Morning-N' },
            { title: '2407', viewSrc: 'Lv23-360-Morning-S' },
            { title: '2415', viewSrc: 'Lv23-360-Morning-N' },
            { title: '2507', viewSrc: 'Lv26-360-Morning-S' },
            { title: '2515', viewSrc: 'Lv26-360-Morning-N' },
            { title: '2607', viewSrc: 'Lv26-360-Morning-S' },
            { title: '2615', viewSrc: 'Lv26-360-Morning-N' },
            { title: '2707', viewSrc: 'Lv26-360-Morning-S' },
            { title: '2715', viewSrc: 'Lv26-360-Morning-N' },
            { title: '2807', viewSrc: 'Lv30-360-Morning-S' },
            { title: '2815', viewSrc: 'Lv30-360-Morning-N' },
            { title: '2907', viewSrc: 'Lv30-360-Morning-S' },
            { title: '2915', viewSrc: 'Lv30-360-Morning-N' },
            { title: '3007', viewSrc: 'Lv30-360-Morning-S' },
            { title: '3015', viewSrc: 'Lv30-360-Morning-N' },
        ],
        'L2CS.1':[
            { title: '1307', viewSrc: 'Lv14-360-Morning-S' },
            { title: '1315', viewSrc: 'Lv14-360-Morning-N' },
            { title: '1407', viewSrc: 'Lv14-360-Morning-S' },
            { title: '1415', viewSrc: 'Lv14-360-Morning-N' },
            { title: '1507', viewSrc: 'Lv14-360-Morning-S' },
            { title: '1515', viewSrc: 'Lv14-360-Morning-N' },
            { title: '1807', viewSrc: 'Lv17-360-Morning-S' },
            { title: '1815', viewSrc: 'Lv17-360-Morning-N' },
            { title: '1907', viewSrc: 'Lv20-360-Morning-S' },
            { title: '1915', viewSrc: 'Lv20-360-Morning-N' },
            { title: '2007', viewSrc: 'Lv20-360-Morning-S' },
            { title: '2015', viewSrc: 'Lv20-360-Morning-N' },
        ],
        'L2DS.1':[
            { title: '1302', viewSrc: 'Lv14-360-Morning-E' },
            { title: '1310', viewSrc: 'Lv14-360-Morning-W' },
            { title: '1402', viewSrc: 'Lv14-360-Morning-E' },
            { title: '1410', viewSrc: 'Lv14-360-Morning-W' },
            { title: '1502', viewSrc: 'Lv14-360-Morning-E' },
            { title: '1510', viewSrc: 'Lv14-360-Morning-W' },
            { title: '1802', viewSrc: 'Lv17-360-Morning-E' },
            { title: '1810', viewSrc: 'Lv17-360-Morning-W' },
            { title: '1902', viewSrc: 'Lv20-360-Morning-E' },
            { title: '1910', viewSrc: 'Lv20-360-Morning-W' },
            { title: '2002', viewSrc: 'Lv20-360-Morning-E' },
            { title: '2010', viewSrc: 'Lv20-360-Morning-W' },
        ],
        'L2ES.1':[
            { title: '1607', viewSrc: 'Lv17-360-Morning-S' },
            { title: '1615', viewSrc: 'Lv17-360-Morning-N' },
            { title: '1707', viewSrc: 'Lv17-360-Morning-S' },
            { title: '1715', viewSrc: 'Lv17-360-Morning-N' },
        ],
        'L2FS.1':[
            { title: '1602', viewSrc: 'Lv17-360-Morning-E' },
            { title: '1610', viewSrc: 'Lv17-360-Morning-W' },
            { title: '1702', viewSrc: 'Lv17-360-Morning-E' },
            { title: '1710', viewSrc: 'Lv17-360-Morning-W' },
        ],  
    }
}
