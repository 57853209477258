import React, { useRef } from 'react';
import { utilAnimate } from '../../util/utilAnimate';
import { connect } from 'react-redux';


function ViewMajorProjectsProject ( { year, currYear, project }) {
    
  const imgRef = useRef(null);

  if (imgRef.current) {
      currYear >= year ? utilAnimate.fadeIn(imgRef.current) : utilAnimate.fadeOut(imgRef.current);
  }

  return (
      <img ref={ imgRef } className='major-projects--project' src={ project.src } style={ { left: project.xPos + 'px', top: project.yPos + 'px' } } alt='project' />
  );

}


const mapStateToProps = (state) => ({
  currYear: state.majorProjects.currYear
});

export default connect(mapStateToProps)(ViewMajorProjectsProject);