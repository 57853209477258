
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import ConnectButtonAmenitiesHotspot from './ConnectButtonAmenitiesHotspot'


function ViewAmenitiesLevel ( { levelData, currLevel } ) {
    
    const display = levelData.title === currLevel.title ? 'block' : 'none'; //only if it's the currently active item

    const getHotspots = () => {
        const btnList = [];
        const dataList = levelData.hotSpots; 
        for (let i = 0; i < dataList.length; i++) { 
            btnList.push(
                <ConnectButtonAmenitiesHotspot key={ i } data={ dataList[i] } onSelected={ onHotspotSelected } />
            );
        }
        return btnList;
    } 
    
    const hotspotsContainer = useRef(null);
    const onHotspotSelected = (hotspot) => {
        hotspotsContainer.current.append(hotspot); //to ensure it appears over the other hotspots
    }

    return (
        <div style={ { display: display } }>
            <img src={ levelData.src } alt='amenities view' />
            <div className='features--hotspots-container' ref={ hotspotsContainer }>
                { getHotspots() }
            </div>
        </div>
    );

}


const mapStateToProps = (state) => ({
    currLevel: state.amenities.currLevel
});

export default connect(mapStateToProps)(ViewAmenitiesLevel);