import React from 'react';
import { connect } from 'react-redux';
import { selectYear } from './';


function ButtonMajorProjectsYear ( { year, currYear, selectYear } ) {
  
  return (
    <button className={ year === currYear ? 'major-projects--btn__active' : 'major-projects--btn' } onClick={ () => selectYear(year) } >
      { year }
    </button>
  );
  
}


const mapStateToProps = (state) => ({
  currYear: state.majorProjects.currYear
});

const mapDispatchToProps = (dispatch) => ({
  selectYear: (currYear) => dispatch(selectYear(currYear))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonMajorProjectsYear);