
import React from 'react';
import { data } from './dataMajorProjects';
import ConnectViewMajorProjectsProject from './ConnectViewMajorProjectsProject';
import ViewMajorProjectsMenu from './ViewMajorProjectsMenu';
import LoadContainer from '../../shared/LoadContainer';
import './stylesMajorProjects.scss';


export default function ViewMajorProjects () {
    
    const projects = [];
    let currYear;
    let currProject;
    let currIndex = 0;
    for (let i = 0; i < data.length; i++) { 
        currYear = data[i];
        for (let j = 0; j < currYear.projects.length; j++) {
            currProject = currYear.projects[j];
            projects.push(
                <ConnectViewMajorProjectsProject key={ currIndex } year={ currYear.year } project={ currProject } />
            );
            currIndex++;
        }
    }
    
    return (
        <LoadContainer>
            <img className='shared--img-fill major-projects--bkg' src='img/location-major-projects/bkg--major-projects.jpg' width='1920' height='1080' alt='Bkg' />
            <div className='major-projects--frg'> 
                { projects }
            </div>
            <ViewMajorProjectsMenu />
        </LoadContainer>
    );
}